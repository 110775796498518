import React, { useState } from "react";
import axios from "axios";
import MiniDrawer from "../../tools/MiniDrawer";
import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridToolbarContainer,
  frFR,
} from "@mui/x-data-grid";
import {
  Box,
  Breadcrumbs,
  Button,
  Link,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FaDatabase } from "react-icons/fa";
import { PaginationDatagrid } from "../../tools/PaginationDatagrid";
import { useMsal } from "@azure/msal-react";
import CsvToJsonConverter from "../../tools/CsvToJsonConverterComponent/CsvToJsonConverter";
import { MdOutlineRestartAlt } from "react-icons/md";

const ImportLAD: React.FC = () => {
  const { instance, accounts, inProgress } = useMsal();

  const [DatagridDensity, setDatagridDensity] = useState<string>("compact");
  const isSmallScreen = useMediaQuery("(max-width:1300px)");

  const [rows, setRows] = useState<any[]>([]);
  const columns: GridColDef[] = [
    { field: "code_adherent", headerName: "Code adhérent", width: 150 },
    { field: "sku", headerName: "Code EAN", width: 150 },
    {
      field: "livrable_domicile",
      headerName: "Livrable à Domicile",
      width: 180,
    },
    { field: "exclu_web", headerName: "Exclu Web", width: 150 },
  ];

  const template: any = {
    code_adherent: "",
    sku: "",
    livrable_domicile: "",
    exclu_web: "",
  };

  const [error, setError] = useState<string | null>(null);
  const [colorError, setColorError] = useState("error");

  const itemsPerPage = 20;
  const totalPages = Math.ceil(rows.length / itemsPerPage);

  const [isSendData, setIsSendData] = useState(false);

  const [widthDrawer, setWidthDrawer] = useState("");

  const [resetFile, setResetFile] = useState(false);

  const handleReset = () => {
    setResetFile(true);
    setError(null);
    setColorError("error");
    setIsSendData(false);
  };

  const sendDataToAPI = async (data: any[]) => {
    const batchSize = 5000;

    // Filter out empty rows
    const filteredData = data.filter(
      (row) =>
        row.code_adherent?.trim() !== "" ||
        row.sku?.trim() !== "" ||
        row.livrable_domicile?.trim() !== "" ||
        row.exclu_web?.trim() !== ""
    );

    setIsSendData(true);

    for (let start = 0; start < filteredData.length; start += batchSize) {
      const end = Math.min(start + batchSize, filteredData.length);
      const batch = filteredData.slice(start, end);

      const ArticlesData = batch.map((item) => {
        let Livrable_domicile_temp = item.livrable_domicile === "1";
        let Exclu_web_temp = item.exclu_web === "1";

        return {
          Code_Adherent: item.code_adherent,
          EAN: item.sku,
          Livrable_Domicile: Livrable_domicile_temp,
          Exclu_Web: Exclu_web_temp,
        };
      });

      const payload = {
        ArticlesData: ArticlesData,
        Article_LENGTH: ArticlesData.length,
      };

      try {
        let token = instance?.getAllAccounts()[0].idToken ?? "";
        await axios.post(
          "https://api.prod.gcp.toutfaire.com/v1/gw/v1/apitools/ARTLAD/artlad",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        setError(`Erreur lors de l'envoi des données suivantes :`);
        setColorError("error");
        setRows(filteredData.slice(start, end));
        break;
      }
    }
  };

  const handleDataSending = () => {
    try {
      sendDataToAPI(rows);
      setRows([]);
      setError("L'envoi des données a été réalisé avec succès.");
      setColorError("green");
    } catch (apiError) {
      setError("Erreur lors de l'envoi des données. Veuillez réessayer.");
      setColorError("error");
    }
  };

  const CustomDatagrid = styled(DataGrid)<DataGridProps>(() => ({
    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
      {
        outline: "none",
      },
  }));

  const CustomToolBar = () => {
    return (
      <GridToolbarContainer>
        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Données du fichier importé
          </Typography>
        </Box>
      </GridToolbarContainer>
    );
  };

  let height = 15;
  switch (DatagridDensity) {
    case "compact":
      height = 15;
      break;
    case "comfortable":
      height = 30;
      break;
    default:
      height = 20;
  }

  const buttonStyle = {
    color: "black",
    background: "#FFD100",
    cursor: "pointer",
  };

  return (
    <MiniDrawer width={widthDrawer} setWidth={setWidthDrawer}>
      <Box style={{ flexGrow: 1, padding: "85px 0px 0px 32px" }}>
        <div style={{ flexGrow: 1, alignItems: "center", width: widthDrawer }}>
          {/* Header / breadcrums  */}
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Accueil
            </Link>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography color="text.primary">
                Import LAD - Exclusivité web
              </Typography>
            </div>
          </Breadcrumbs>
          {/* title  */}
          <Typography
            variant="h3"
            style={{
              margin: "10px 10px 10px 0",
              fontWeight: "bold",
              fontSize: "32px",
            }}
          >
            Import LAD - Exclusivité web
          </Typography>
          <div style={{ display: "flex" }}>
            <CsvToJsonConverter
              template={template}
              setRows={setRows}
              newFile={resetFile}
              setNewFile={setResetFile}
              skipLines={1}
            />
            {rows.length > 0 && (
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <div>
                  <input
                    style={{ display: "none" }}
                    id="data-sending"
                    onClick={handleDataSending}
                    disabled={isSendData}
                  />
                  <label htmlFor="data-sending">
                    <Button
                      variant="contained"
                      disabled={isSendData}
                      component="span"
                      style={buttonStyle}
                    >
                      <span style={{ marginTop: 3 }}>
                        Envoi des données &nbsp;
                      </span>
                      <FaDatabase />
                    </Button>
                  </label>
                </div>
                <div>
                  <Button
                    style={buttonStyle}
                    variant="contained"
                    onClick={handleReset}
                  >
                    Soumettre un nouveau fichier&nbsp;
                    <MdOutlineRestartAlt />
                  </Button>
                </div>
              </div>
            )}
            {rows.length == 0 && isSendData && (
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <div>
                  <Button
                    style={buttonStyle}
                    variant="contained"
                    onClick={handleReset}
                  >
                    Soumettre un nouveau fichier&nbsp;
                    <MdOutlineRestartAlt />
                  </Button>
                </div>
              </div>
            )}
          </div>
          {error && (
            <Typography
              color={colorError === "error" ? "error" : undefined}
              variant="body1"
              style={{
                color: colorError === "green" ? "green" : undefined,
                whiteSpace: "pre-line",
                marginTop: 20,
              }}
            >
              {error}
            </Typography>
          )}
          {rows.length > 0 && (
            <Box marginRight={"25px"}>
              <CustomDatagrid
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                  maxWidth: isSmallScreen ? "89.8vw" : "93.2vw",
                  width: isSmallScreen ? "100%" : "auto", //

                  "@media (min-width: 2005px)": {
                    // Large screen breakpoint (adjust as needed)
                    maxWidth: "93.5vw", // Large screen width
                  },
                  "@media (min-width: 2365px)": {
                    // Large screen breakpoint (adjust as needed)
                    maxWidth: "94.5vw", // Large screen width
                  },
                  "@media (min-width: 2495px)": {
                    // Large screen breakpoint (adjust as needed)
                    maxWidth: "94.7vw", // Large screen width
                  },
                  "@media (min-width: 2565px)": {
                    // Large screen breakpoint (adjust as needed)
                    maxWidth: "94.9vw", // Large screen width
                  },
                }}
                density="compact"
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                disableRowSelectionOnClick
                slots={{
                  toolbar: CustomToolBar,
                  pagination: PaginationDatagrid,
                }}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: itemsPerPage, page: 0 },
                  },
                }}
                slotProps={{
                  toolbar: { csvOptions: { allColumns: false } },
                  pagination: {
                    itemsCount: rows.length,
                    itemsTitle: "éléments",
                    itemsperpage: itemsPerPage,
                    totalPages: totalPages,
                  },
                }}
                onStateChange={(v) => {
                  const density = v.density.value as string;
                  if (
                    density === "compact" ||
                    density === "standard" ||
                    density === "comfortable"
                  ) {
                    setDatagridDensity(density);
                  }
                }}
                rows={rows}
                columns={columns}
              />
            </Box>
          )}
        </div>
      </Box>
    </MiniDrawer>
  );
};

export default ImportLAD;

import * as React from "react";
import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import bg from "../../../assets/bg_login.png";
import logo from "../../../assets/logo_tout_faire.svg";
import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Link,
} from "@mui/material";
import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import MiniDrawer from "../../tools/MiniDrawer";
import { generateRoutes } from "../../../Routes";
import LoadingScreen from "../../LoadingScreen";
import { motion } from "framer-motion";

const Accueil: React.FC = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [rolesLoaded, setRolesLoaded] = useState<boolean>(false); // Track if roles are loaded
  const [routesTest, setRoutesTest] = useState<any>({}); // Store the generated routes
  const tokenRequest = {
    scopes: ["openid", "profile", "User.Read"],
    forceRefresh: true, // Force token refresh
  };

  const Account = instance.getAllAccounts()[0];

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1024px)");

    const handleScreenChange = (e: any) => {
      setIsSmallScreen(e.matches);
    };

    mediaQuery.addListener(handleScreenChange);

    handleScreenChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleScreenChange);
    };
  }, []);

  const getToken = async (account: AccountInfo) => {
    try {
      const response = await instance.acquireTokenSilent({
        ...tokenRequest,
        account,
      });
      return response.idToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        const response = await instance.acquireTokenPopup(tokenRequest);
        return response.idToken;
      } else {
        throw error;
      }
    }
  };

  const fetchRole = async (account: any) => {
    try {
      const token = await getToken(account);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      const response = await axios.get(
        `https://api.prod.gcp.toutfaire.com/v1/gw/v1/apitools/users/${account.username}`
      );
      response.data.out_data.PriceComparatorRights.databases.forEach(
        (d: any) => {
          if (d.clientName === "") d.clientName = d.databaseName;
        }
      );

      let arrayRights: string[] = [];

      if (
        response.data.out_data.PriceComparatorRights.databases.length === 0 ||
        response.data.out_data.userRole === ""
      ) {
        localStorage.setItem("RightPriceComparator", "false");
      }

      if (response.data.out_data.PriceComparatorRights.accessRights === "O") {
        arrayRights.push("Plateform/ComparePrices");
      }
      if (response.data.out_data.AddProductsRights.accessRights === "O") {
        arrayRights.push("Plateform/AddArticles");
      }
      if (response.data.out_data.EditProductRights.accessRights === "O") {
        arrayRights.push("Plateform/EditProduct");
      }
      if (response.data.out_data.ImportLadRights.accessRights === "O") {
        arrayRights.push("Ecommerce/ImportLAD");
      }
      if (response.data.out_data.SupportRights.accessRights === "O") {
        arrayRights.push("Support/ProductInjector");
      }
      if (response.data.out_data.ComparePerAdhRights.accessRights === "O") {
        arrayRights.push("Plateform/ComparePricesPerAdh");
      }
      if (response.data.out_data.StatsMarketing.accessRights === "O") {
        arrayRights.push("Marketing/StatsMarketing");
      }
      // Store rights in localStorage
      localStorage.setItem("rights", JSON.stringify(arrayRights));

      // Generate routes based on the updated rights
      const routes = generateRoutes(JSON.stringify(arrayRights));
      setRoutesTest(routes); // Set routes state to trigger re-render

      setRolesLoaded(true); // Mark roles as loaded

      // Automatically navigate if there's only one right
      if (arrayRights.length === 1) {
        navigate(arrayRights[0]);
      }
    } catch (error) {
      console.error("Error fetching role:", error);
    }
  };

  useEffect(() => {
    if (Account && !rolesLoaded) {
      fetchRole(Account);
    }
  }, [Account, rolesLoaded]); // Fetch roles when Account changes

  if (!rolesLoaded && !localStorage.getItem("rights")) {
    // Show loading or placeholder content until roles are fetched
    return (
      <div>
        <LoadingScreen />
      </div>
    );
  }

  return (
    <MiniDrawer>
      <ThemeProvider theme={createTheme()}>
        <Grid
          container
          component="main"
          sx={{
            height: "100vh",
            display: "flex",
            flexDirection: "row",
            overflow: "hidden",
            padding: "60px 0 0 0",
          }}
        >
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            sx={{
              backgroundColor: "#FFD100",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "191px",
                padding: "90px 0 0 0",
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                style={{ fontWeight: "bold" }}
              >
                TOUT FAIRE CONNECT
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={logo} alt="Logo" />
                <Typography component="h1" variant="h5">
                  Accueil
                </Typography>
                <Box
                  component="form"
                  noValidate
                  sx={{ mt: 1 }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </div>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={7}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "50px",
              padding: "16px",
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[50]
                  : theme.palette.grey[900],
            }}
          >
            {!rolesLoaded && <CircularProgress color="warning" />}
            {Object.keys(routesTest).map((routeName) => {
              const route = routesTest[routeName];

              return (
                routeName !== "Accueil" && (
                  <div
                    key={routeName}
                    style={{
                      position: "relative",
                      maxWidth: isSmallScreen ? "230px" : "350px",
                      backgroundColor: "#4c4c4c",
                      borderRadius: "10px",
                    }}
                  >
                    <CardContent
                      sx={{
                        minHeight: "480px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          fontWeight: "bold",
                          color: "white",
                          textDecoration: "none",
                        }}
                      >
                        {routeName}
                      </Typography>

                      {route.subRoutes
                        ? Object.keys(route.subRoutes).map((subRouteName) => {
                            const subRoute = route.subRoutes![subRouteName];
                            const subRoutePath = subRoute.path;
                            const subRouteDescription =
                              subRoute.subRouteDescription;

                            return (
                              <motion.div
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.9 }}
                              >
                                <div
                                  style={{
                                    borderRadius: "10px",
                                    backgroundColor: "#ffd100",
                                    color: "black",
                                    textDecoration: "none",
                                    fontWeight: "bold",
                                    padding: "10px 13px 0px 10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    navigate(subRoutePath);
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        color: "black",
                                        textDecoration: "none",
                                      }}
                                    >
                                      {subRouteName}
                                    </div>
                                    <p
                                      style={{
                                        textAlign: "center",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      {subRouteDescription}
                                    </p>
                                  </div>
                                </div>
                              </motion.div>
                            );
                          })
                        : null}
                    </CardContent>
                  </div>
                )
              );
            })}
          </Grid>
        </Grid>
      </ThemeProvider>
    </MiniDrawer>
  );
};

export default Accueil;

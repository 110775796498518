let fakeData2
export default fakeData2 =
{
    "out_code": "000",
    "out_message": "Comparaison Adh pour le code EAN :2084773174104",
    "out_ComparedPrices": {
        "Compare": [
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "AG",
                        "ProductCode": "B0616001",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 283,
                        "SupplierName": "PLATEFORME TOUT FAIRE"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 92.00,
                        "ApplicationDate": "21/02/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 61.3350,
                        "ApplicationDate": "19/07/24"
                    }
                },
                "second_DB": "AGRINORD"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "AJ",
                        "ProductCode": "K2216001",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 2222,
                        "SupplierName": "PLATEFORME TOUT FAIRE"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "21/02/23"
                    }
                },
                "second_DB": "AJIMAT"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "AP",
                        "ProductCode": "111056",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 270042,
                        "SupplierName": "PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "01/03/23"
                    }
                },
                "second_DB": "APMAT"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "Z7",
                        "ProductCode": "135235",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 261321,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE IRONSIDE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 92.00,
                        "ApplicationDate": "07/03/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "01/02/23"
                    }
                },
                "second_DB": "BAUDE"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "B2",
                        "ProductCode": "110146",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 100220,
                        "SupplierName": "PLATEFORME TOUTFAIRE"
                    },
                    "pfProductsNames": {
                        "ProductName": "A-P IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 91.86,
                        "ApplicationDate": "23/02/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "23/02/23"
                    }
                },
                "second_DB": "BOISMAT"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "CO",
                        "ProductCode": "K2216001",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 260083,
                        "SupplierName": "PLATEFORME TF"
                    },
                    "pfProductsNames": {
                        "ProductName": "IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "01/02/23"
                    }
                },
                "second_DB": "COLLIN"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "DL",
                        "ProductCode": "825496",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 262152,
                        "SupplierName": "PLATEFORME TOUT FAIRE"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 104.77,
                        "ApplicationDate": "31/05/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "06/03/23"
                    }
                },
                "second_DB": "DELAGE"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "FA",
                        "ProductCode": "177140",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 1007,
                        "SupplierName": "PLATEFORME TOUT FAIRE"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "22/02/23"
                    }
                },
                "second_DB": "FAGES"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "FE",
                        "ProductCode": "127588",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 260453,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 116.82,
                        "ApplicationDate": "15/02/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "15/02/23"
                    }
                },
                "second_DB": "FELIX"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "GI",
                        "ProductCode": "115644",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 260182,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "01/04/23"
                    }
                },
                "second_DB": "GILLES"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "GM",
                        "ProductCode": "IS317410",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 260740,
                        "SupplierName": "PLATEFORME                (PF)"
                    },
                    "pfProductsNames": {
                        "ProductName": "LOT ECLAIRAGE MINI PHARE + LAMPE LED",
                        "ProductName2": "RECHARGEABLE IRONSIDE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "PC",
                        "StockUnity": "PC",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 125.12,
                        "ApplicationDate": "01/02/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "01/02/23"
                    }
                },
                "second_DB": "GRANDMAT"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "JM",
                        "ProductCode": "108186",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 669,
                        "SupplierName": "PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 91.86,
                        "ApplicationDate": "27/02/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "09/02/23"
                    }
                },
                "second_DB": "JMATERIAUX"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "LA",
                        "ProductCode": "EL317410",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 270578,
                        "SupplierName": "PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "LOT ECLAIRAGE MINI PHARE 1585LUM + LAMPE",
                        "ProductName2": "LED RECHARGEABLE 750 LUMENS"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 80.0000,
                        "BuyingPrice": 80.0000,
                        "ApplicationDate": "01/04/23"
                    }
                },
                "second_DB": "LANNOY"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "L1",
                        "ProductCode": "131383",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 260462,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 91.86,
                        "ApplicationDate": "01/03/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "22/02/23"
                    }
                },
                "second_DB": "LAYROL"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "LF",
                        "ProductCode": "K2216001",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 260273,
                        "SupplierName": "PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "03/03/23"
                    }
                },
                "second_DB": "LEFRANCOIS"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "LM",
                        "ProductCode": "130162",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 99940,
                        "SupplierName": "PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "28/02/23"
                    }
                },
                "second_DB": "LIMOUSIN"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "MA",
                        "ProductCode": "K2216001",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 260116,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 110.39,
                        "ApplicationDate": "24/02/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "24/02/23"
                    }
                },
                "second_DB": "MARRE"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "MO",
                        "ProductCode": "109902",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 261400,
                        "SupplierName": "PLATEFORME TOUT FAIRE"
                    },
                    "pfProductsNames": {
                        "ProductName": "LOT ECLAIRAGE MINI PHARE 1585 LUMENS +",
                        "ProductName2": "BALADEUSE RECHARGEABLE 750 LUMENS"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "PC",
                        "StockUnity": "PC",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.6400,
                        "BuyingPrice": 81.6400,
                        "ApplicationDate": "22/02/23"
                    }
                },
                "second_DB": "MOULET"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "ME",
                        "ProductCode": "306404",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 261025,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 91.99,
                        "ApplicationDate": "06/03/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "06/03/23"
                    }
                },
                "second_DB": "MTETAMPOIS"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "MU",
                        "ProductCode": "50016159",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 260507,
                        "SupplierName": "PLATEFORME TOUT FAIRE"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 90.8700,
                        "BuyingPrice": 90.8700,
                        "ApplicationDate": "01/04/23"
                    }
                },
                "second_DB": "MULTIMAT"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "PI",
                        "ProductCode": "52009944",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 10061,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "23/02/23"
                    }
                },
                "second_DB": "PISANO"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "PM",
                        "ProductCode": "215041",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 260453,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "18/02/23"
                    }
                },
                "second_DB": "PMD"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "PG",
                        "ProductCode": "215041DR",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 105037,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "18/02/23"
                    }
                },
                "second_DB": "PROVENCALE"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 0.000,
                        "Socity": "SC",
                        "ProductCode": "110985",
                        "ReffSupplier": "296077",
                        "SupplierCode": 50260,
                        "SupplierName": "THERMOR"
                    },
                    "pfProductsNames": {
                        "ProductName": "ADAPTATEUR VENTOUSE POUR AEROMAX",
                        "ProductName2": ""
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 76.50,
                        "ApplicationDate": "11/03/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 102.0000,
                        "BuyingPrice": 49.9800,
                        "ApplicationDate": "01/02/23"
                    }
                },
                "second_DB": "SANITCONFO"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "SO",
                        "ProductCode": "121050",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 262686,
                        "SupplierName": "PLATEFORME TOUT-FAIRE"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 92.00,
                        "ApplicationDate": "10/03/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "22/02/23"
                    }
                },
                "second_DB": "SOMATRANS"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "2A",
                        "ProductCode": "108939",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 752,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "LOT ECLAIRAGE MINI PHARE 1585  + LAMPE",
                        "ProductName2": "BALADEUSE RECHARGEABLE 750 LUMENK2216001"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 116.82,
                        "ApplicationDate": "27/02/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "01/04/23"
                    }
                },
                "second_DB": "TANET"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "C3",
                        "ProductCode": "124048",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 260995,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "22/02/23"
                    }
                },
                "second_DB": "TAUDE"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "N1",
                        "ProductCode": "20017369",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 260480,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 91.44,
                        "ApplicationDate": "03/01/24"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "15/02/23"
                    }
                },
                "second_DB": "TBELLET"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "36",
                        "ProductCode": "10003331",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 555,
                        "SupplierName": "PLATEFORME TOUT FAIRE"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 105.29,
                        "ApplicationDate": "01/04/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "21/02/23"
                    }
                },
                "second_DB": "TBOMAT"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "DI",
                        "ProductCode": "K2216001",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 260804,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "22/02/23"
                    }
                },
                "second_DB": "TCHAMPI"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "MG",
                        "ProductCode": "332148",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 18760,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "25/02/23"
                    }
                },
                "second_DB": "TCOVAMAT"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "D1",
                        "ProductCode": "1015460",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 300281,
                        "SupplierName": "PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "IS-LOT ECLAIRAGE MINI PHARE + LAMPE LEAD",
                        "ProductName2": "RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 91.99,
                        "ApplicationDate": "24/02/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "24/02/23"
                    }
                },
                "second_DB": "TDESHAYES"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "F7",
                        "ProductCode": "139907",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 3179,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "13/03/23"
                    }
                },
                "second_DB": "TEUROMAT"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "8P",
                        "ProductCode": "102548",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 199,
                        "SupplierName": "PLATEFORME TOUT FAIRE"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "25/02/23"
                    }
                },
                "second_DB": "TFIXECO"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "8E",
                        "ProductCode": "1002803",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 263665,
                        "SupplierName": "PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "01/04/23"
                    }
                },
                "second_DB": "TFOUQUES"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "I9",
                        "ProductCode": "115298",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 260675,
                        "SupplierName": "PLATEFORME TOUTFAIRE"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 99.17,
                        "ApplicationDate": "01/04/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "24/02/23"
                    }
                },
                "second_DB": "TGINESTET"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "9O",
                        "ProductCode": "105632",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 260399,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": " ECLAIRAGE MINI PHARE",
                        "ProductName2": ""
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 64.50,
                        "ApplicationDate": "30/11/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 47.7800,
                        "BuyingPrice": 47.7800,
                        "ApplicationDate": "15/02/23"
                    }
                },
                "second_DB": "THOME"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "1K",
                        "ProductCode": "113343",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 18770,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "LOT ECLAIRAGE MINI PHARE + LAMPE LED",
                        "ProductName2": "K2216001       SUP CHEZ FOURNISSEUR"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 91.86,
                        "ApplicationDate": "08/03/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "01/03/23"
                    }
                },
                "second_DB": "TJACOB"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "A5",
                        "ProductCode": "121911",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 260134,
                        "SupplierName": "PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "01/04/23"
                    }
                },
                "second_DB": "TLANDREAU"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "6C",
                        "ProductCode": "346198",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 775,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 147.20,
                        "ApplicationDate": "25/02/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "25/02/23"
                    }
                },
                "second_DB": "TLEBRETON"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "2B",
                        "ProductCode": "K2216001",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 566,
                        "SupplierName": "PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 141.76,
                        "ApplicationDate": "30/03/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 90.8700,
                        "BuyingPrice": 90.8700,
                        "ApplicationDate": "01/01/24"
                    }
                },
                "second_DB": "TLILIAN"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "1A",
                        "ProductCode": "111970",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 900413,
                        "SupplierName": "PLATEFORME TOUT FAIRE"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "02/12/23"
                    }
                },
                "second_DB": "TMCC"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "O3",
                        "ProductCode": "219074",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 104004,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 114.49,
                        "ApplicationDate": "28/02/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "28/02/23"
                    }
                },
                "second_DB": "TRATEAU"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "3R",
                        "ProductCode": "146157",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 118821,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 130.85,
                        "ApplicationDate": "15/02/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "15/02/23"
                    }
                },
                "second_DB": "TROMERA"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "3Y",
                        "ProductCode": "100922",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 1694,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 107.75,
                        "ApplicationDate": "25/02/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "25/02/23"
                    }
                },
                "second_DB": "TSABLEMAT"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 0.000,
                        "Socity": "SD",
                        "ProductCode": "100194",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 812181,
                        "SupplierName": "TOUT FAIRE PLATEFORME"
                    },
                    "pfProductsNames": {
                        "ProductName": "LOT ECLAIR MINI PHARE+LAMPE LEAD RECH.IS",
                        "ProductName2": ""
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 0.00,
                        "ApplicationDate": ""
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 0.0000,
                        "BuyingPrice": 0.0000,
                        "ApplicationDate": ""
                    }
                },
                "second_DB": "TSODIAC"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "02",
                        "ProductCode": "10005583",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 961,
                        "SupplierName": "TOUT FAIRE PLATEFORME  DZB"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 93.83,
                        "ApplicationDate": "13/02/25"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "23/02/23"
                    }
                },
                "second_DB": "TVCMC"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "5R",
                        "ProductCode": "102395",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 100294,
                        "SupplierName": "CENTRALE TOUT FAIRE"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 108.10,
                        "ApplicationDate": "03/11/23"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "01/04/23"
                    }
                },
                "second_DB": "TVEYRIERE"
            },
            {
                "gencod": "2084773174104",
                "clientData": {
                    "pfProductCodes": {
                        "artpaq": 1.000,
                        "Socity": "VI",
                        "ProductCode": "10024408",
                        "ReffSupplier": "K2216001",
                        "SupplierCode": 800524,
                        "SupplierName": "PLATEFORME TOUT FAIRE"
                    },
                    "pfProductsNames": {
                        "ProductName": "AP- IS-LOT ECLAIRAGE MINI PHARE + LAMPE",
                        "ProductName2": "LEAD RECHARGEABLE"
                    },
                    "pfProductsUnities": {
                        "BuyingUnity": "UN",
                        "SalesUnity": "UN",
                        "StockUnity": "UN",
                        "CoeffBuyingOnStock": 1.000000,
                        "CoeffStockOnSales": 1.000000
                    },
                    "pfProductPrice": {
                        "SalePrice": 95.67,
                        "ApplicationDate": "07/02/25"
                    },
                    "pfProductBuyingPrice": {
                        "GrossBuyingPrice": 81.7800,
                        "BuyingPrice": 81.7800,
                        "ApplicationDate": "07/02/25"
                    }
                },
                "second_DB": "VIMOND"
            }
        ]
    }
}
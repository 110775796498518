import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Link,
  Box,
  styled,
  ButtonProps,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
  useMediaQuery,
  Stack,
  Input,
} from "@mui/material";
import axios from "axios";
import MiniDrawer from "../../tools/MiniDrawer";
import { purple } from "@mui/material/colors";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  PieLabelRenderProps,
  TooltipProps,
  Label,
} from "recharts";
import {
  DataGrid,
  frFR,
  GridColDef,
  GridCsvExportOptions,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueGetterParams,
  nlNL,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import SouthEastIcon from "@mui/icons-material/SouthEast";

import axiosInstance from "../../../axiosInstance";
import { PaginationDatagrid } from "../../tools/PaginationDatagrid";
import fakeData from "../fakeData";
import { useMsal } from "@azure/msal-react";
import { Backdrop, CircularProgress } from "@mui/material";
import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";
import { SaveAlt } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Autocomplete } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { GrCompare } from "react-icons/gr";
import { enqueueSnackbar } from "notistack";
import * as XLSX from "xlsx";
import { motion } from "framer-motion";
import stringToColor from "string-to-color";
import fakeData2 from "../fakeData2";
const ComparePricesPerAdh = () => {
  const [loading, setLoading] = useState(false);
  //  const apiRef = useGridApiContext();

  const [priceComparisonResult, setPriceComparisonResult] = useState<any[]>([]);
  const [totalResultForCsv, setTotalResultForCsv] = useState<any[]>([]);
  const [stats, setStats] = useState(0);
  const [selectedClientBase, setSelectedClientBase] = useState("");
  const [selectedClientName, setSelectedClientName] = useState("");
  const [displayedClientName, setDisplayedClientName] = useState("");

  const [gencod, setGencod] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [totalPages, setPages] = useState("");
  const [page, setPage] = React.useState(1);
  const [wrongItems, setwrongItems] = React.useState(1);
  const [itemsBoughtOnPlateform, setitemsBoughtOnPlateform] = React.useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [roleError, setRoleError] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  const [role, setRole] = useState("");
  const [totalPagesRecord, setTotalPages] = useState("");
  const [tokenMsal, setTokenMsal] = useState<string | null>(null);
  const { instance, accounts, inProgress } = useMsal();
  const msalInstance = instance;
  const [showCharts, setShowCharts] = useState(() => {
    return false;
    // const storedShowCharts = localStorage.getItem("showCharts");
    // return storedShowCharts !== null ? JSON.parse(storedShowCharts) : false;
  }); // State to control visibility of charts
  const isSmallScreen = useMediaQuery("(max-width:1300px)");
  const isBigScreen = useMediaQuery("(max-width:2500px)");

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [idToken, setIdToken] = useState<string | null>(null);
  const [selectedEndpoint, setSelectedEndpoint] = useState("");
  const [widthDrawer, setWidthDrawer] = useState("");
  const [selectedOption, setSelectedOption] = useState("compareList");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedFilterOrderby, setSelectedFilterOrderby] = useState("ASC");
  const [chartData, setChartData] = useState([]);
  const optionsForFilter = [
    { label: "fournisseur", value: "frn" },
    { label: "écart", value: "gap" },
    { label: "libellé", value: "lib" },
  ];

  const [validData, setValidData] = useState(false);
  const [errorGencod, setErrorGencod] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const apiRef = useGridApiRef();

  const tokenRequest = {
    scopes: ["openid", "profile", "User.Read"],
    forceRefresh: true, // Force a token refresh
  };

  const getToken = async (account: AccountInfo) => {
    try {
      const response = await msalInstance.acquireTokenSilent({
        ...tokenRequest,
        account,
      });
      console.log(response);
      return response.idToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // If interaction is required, fallback to an interactive method
        const response = await msalInstance.acquireTokenPopup(tokenRequest);
        console.log(response);

        return response.idToken;
      } else {
        console.log("error token ");
        throw error;
      }
    }
  };
  // Temporary vars for dev
  let statError: any;
  //let roleError: any = fakeData.fakerole;
  let reponseError: any;
  let paginationError: any = 1;
  useEffect(() => {
    const fetchRole = async () => {
      if (accounts.length > 0 && inProgress === "none") {
        try {
          const token = await getToken(accounts[0]);
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          const response = await axios.get(
            `https://api.prod.gcp.toutfaire.com/v1/gw/v1/apitools/users/${accounts[0].username}`
          );
          response.data.out_data.PriceComparatorRights.databases.forEach(
            (d: any) => {
              if (d.clientName === "") d.clientName = d.databaseName;
            }
          );
          setRoleError(response.data.out_data.PriceComparatorRights.databases);
          if (
            response.data.out_data.PriceComparatorRights.databases.length ===
              0 ||
            response.data.out_data.userRole === ""
          )
            localStorage.setItem("RightPriceComparator", "false");
        } catch (error) {
          console.error("Error fetching role:", error);
          setRoleError([]);
        }
      }
    };

    fetchRole();
  }, [accounts, inProgress]);

  // utils functions  set base client with select

  const getTokenMsal = async () => {
    setTokenMsal(await getToken(accounts[0]));
  };
  const handlePaginationModelChange = async (newModel: any) => {
    // Start loading
    console.log("new", newModel);
    setPaginationModel(newModel);
    //setTokenMsal(idToken);
    // let tokenMsal = instance?.getAllAccounts()[0].idToken ?? "";

    //console.log("instance", tokenMsal);
    let token = await getToken(instance?.getAllAccounts()[0]);
    axios.defaults.headers.common["Authorization"] = `Bearer ${token ?? ""}`;
    const pageNumber = newModel.page + 1;
    //console.log("test token", tokenMsal);
  };

  const handleGencodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.trim(); // Trim spaces

    if (errorGencod) {
      setErrorGencod(false);
      setErrorMessage("");
    }

    setGencod(newValue); // Update state
  };
  //const parseDate = (dateString:string) => new Date(dateString); // Assuming this is your date parsing function
  // data for the pie chart (data where pf better than cli)

  //

  const retriveRole = async () => {
    let role = "admin";

    return role;
  };

  const handleSubmit = async (
    selectedEndpoint: string = "",
    fromswitch = false
  ) => {
    //await getTokenMsal();
    let token = await getToken(instance?.getAllAccounts()[0]);
    axios.defaults.headers.common["Authorization"] = `Bearer ${token ?? ""}`;
    setSelectedEndpoint(selectedEndpoint);
    setSelectedOption(selectedEndpoint);

    try {
      retriveRole().then((role) => {
        setRole(role);
      });
    } catch {}

    setLoading(true); // Start loading

    try {
      if (gencod !== "") {
        setValidData(false);
        const response = await axios
          .get(
            "https://api.prod.gcp.toutfaire.com/v1/gw/v1/apitools/ComparaisonPerAdh" +
              "?gencod=" +
              gencod,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .finally(() => {
            setLoading(false); // Start loading
          });
        const resultPerAdh: any = response.data.out_ComparedPrices.Compare;
        response.data.out_ComparedPrices.id = 1;
        const dataWithIds = resultPerAdh.map((item: any, index: number) => {
          // Check if item.plateformData.pfProductsUnities.CVSUA is not equal to item.plateformData.pfProductsUnities.CVSUV

          console.log("conversion");
          if (item.clientData.pfProductsUnities.CoeffBuyingOnStock == 0)
            item.clientData.pfProductsUnities.CoeffBuyingOnStock = 1;

          // item.clientData.pfProductBuyingPrice.BuyingPrice =
          //   item.clientData.pfProductBuyingPrice.BuyingPrice /
          //   item.clientData.pfProductsUnities.CoeffBuyingOnStock;

          //   (item.plateformData.pfProductPrice.ARVHTC *
          //     item.plateformData.pfProductsUnities.CVSUSUV) /
          //   item.clientData.pfProductsUnities.CVSUAUS;
          console.log("conversion data", item);
        });
        // Compute the price difference for the graph

        // resultPerAdh.push(response.data.out_ComparedPrices.Compare);
        console.log("yooo", dataWithIds);
        if (resultPerAdh.length == 0) setError("Aucun Resultat trouvé");
        if (response.data.out_code === "000") {
          setPriceComparisonResult(resultPerAdh);
          const chartData2: any = [];
          resultPerAdh.map((item: any) => {
            let temp = {
              name: item.clientData.pfProductCodes.Socity, // Adjust according to your data structure$
              nameDisplay: item.second_DB,
              prix_achat: item.clientData.pfProductBuyingPrice.BuyingPrice,
              prix_vente: item.clientData.pfProductPrice.SalePrice,
            };
            chartData2.push(temp);
          });
          console.log("charts", chartData2);
          setChartData(chartData2);
          if (resultPerAdh.length != 0) setError(null);

          setPages("1");
          setTotalPages("1");

          setPaginationModel({
            page: 0,
            pageSize: 100,
          });

          setitemsBoughtOnPlateform(1);

          setTotalRecords(1);
          setCurrentPage(1);
          setDisplayedClientName(selectedClientName);
          setwrongItems(0);
          setPages("1");
          setTotalPages("1");
        } else {
          setError("code EAN inconnu");
          setErrorGencod(true);
          setErrorMessage("code EAN inconnu");
          setValidData(true);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      reponseError = fakeData2.out_ComparedPrices.Compare;
      setError("Erreur dans la requete ");
      const dataWithIds = reponseError.map((item: any, index: any) => ({
        ...item,
        id: index + 1, // Generates unique IDs starting from 1
      }));

      setitemsBoughtOnPlateform(statError.itemsBoughtOnPlateform);
      setPriceComparisonResult(dataWithIds);
    }
  };

  const parseDate = (dateStr: string): Date | null => {
    if (!dateStr) return null;

    // Check if the date string is in yyyy-mm-dd format
    const isoFormat = /^\d{4}-\d{2}-\d{2}$/;
    if (isoFormat.test(dateStr)) {
      // Parse yyyy-mm-dd format
      const [year, month, day] = dateStr
        .split("-")
        .map((part) => parseInt(part, 10));
      return new Date(year, month - 1, day);
    } else {
      // Assuming default format is dd/mm/yyyy
      const [day, month, year] = dateStr
        .split("/")
        .map((part) => parseInt(part, 10));
      return new Date(year, month - 1, day);
    }
  };

  // Creating columns for the data grid
  const columns: GridColDef[] = [
    {
      field: "Adherent",
      headerName: "Adherent",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) => {
        const artcod = params.row.second_DB;
      },
      renderCell: (params: any) => params.row.second_DB,
    },
    //ffff
    {
      field: "PF.codeSociete",
      headerName: "Code Société ",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.clientData.pfProductCodes.Socity ?? "",
    }, // Added Code Societe field
    {
      field: "type Product",
      headerName: "Code article",
      width: 150,
      sortable: false,
      valueGetter: (params) => {
        const artcod = params.row.clientData.pfProductCodes.ProductCode;
      },
      renderCell: (params: any) =>
        params.row.clientData.pfProductCodes.ProductCode,
    },
    {
      field: "gencod",
      headerName: "Code EAN",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) => {
        const artcod = params.row.gencod;
      },
      renderCell: (params: any) => params.row.gencod,
    },

    {
      field: "BuyingPrice",
      headerName: "Prix d'achat ",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) => {
        const artcod = params.row.clientData.pfProductBuyingPrice.SalePrice;
      },
      renderCell: (params: any) =>
        params.row.clientData.pfProductBuyingPrice.BuyingPrice.toFixed(2) + "€",
    }, // Added Selling Price field for CLI
    {
      field: "plateformData.pfProductPrice.SalePrice",
      headerName: "Prix de Vente plateforme lors de l'achat",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) => {
        const artcod = params.row.clientData.pfProductPrice.SalePrice;
      },
      renderCell: (params: any) =>
        params.row.clientData.pfProductPrice.SalePrice.toFixed(2) + "€",
    }, // Added Selling Price field for PF
    {
      field: "plateformData.pfProductPrice.SalePrice2",
      headerName: "Prix de Vente plateforme actuel",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) => {
        const artcod = params.row.clientData.pfProductPrice.SalePrice2;
      },
      renderCell: (params: any) =>
        params.row.clientData.pfProductPrice.SalePrice2.toFixed(2) + "€",
    }, // Added Selling Price field for PF

    {
      field: "ApplicationDate",
      headerName: "Date d'application prix d'achat ",
      width: 200,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.clientData.pfProductBuyingPrice.ApplicationDate ?? "",
    },
    {
      field: "PF.ApplicationDate",
      headerName: "Date d'application prix de vente ",
      width: 200,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.clientData.pfProductPrice.ApplicationDate ?? "",
    },
    // Added Application Date field for PF // Added Buying Price Net field for PF

    {
      field: "SupplierName2",
      headerName: "Nom Fournisseur ",
      width: 200,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.clientData.pfProductCodes.SupplierName ?? "",
    },
    {
      field: "PF.libe1",
      headerName: "Libellé 1 ",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.clientData.pfProductsNames.ProductName ?? "",
    }, // Added Libe1 field for PF
    {
      field: "PF.lib2",
      headerName: "Libellé 2 ",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.clientData.pfProductsNames.ProductName2 ?? "",
    }, // Added Lib2 field for PF
    // Added UA field for PF
    {
      field: "PF.codeRef",
      headerName: "Référence fournisseur ",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.clientData.pfProductCodes.ReffSupplier ?? "",
    },
    {
      field: "PF.buyingPriceGross",
      headerName: "Prix d'achat brut",
      width: 200,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.clientData.pfProductBuyingPrice.GrossBuyingPrice ?? "",
    }, // Added Buying Price Gross field for PF
    {
      field: "PF.UA",
      headerName: "Unité d'achat",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.clientData.pfProductsUnities.BuyingUnity ?? "",
    },
    {
      field: "PF.UV",
      headerName: "Unité de vente",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.clientData.pfProductsUnities.SalesUnity ?? "",
    }, // Added UV field for PF
    {
      field: "PF.arapaq",
      headerName: "achat par colis de x",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.clientData.pfProductCodes.artpaq ?? "",
    }, //
    {
      field: "prix cession moyen",
      headerName: "Prix de vente moyen CLI  ",
      width: 150,
      sortable: false,
      valueGetter: (params) =>
        parseFloat(params.row.statData.SalesPriceAverage).toFixed(2) + "€",
    },
    {
      field: "prix d'achat moyen",
      headerName: "Prix d'achat moyen CLI",
      width: 150,
      sortable: false,
      valueGetter: (params) =>
        parseFloat(params.row.statData.BuyingPricesAverage).toFixed(2) + "€",
    },

    {
      field: "statData.AvaibleStock",
      headerName: "Stock disponible",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.statData?.AvaibleStock?.toFixed(2) +
        " " +
        (params.row.clientData.pfProductsUnities.StockUnity ?? ""),
    },
    {
      field: "statData.topConcordance",
      headerName: "Top Concordance",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) => params.row.statData?.topConcordance ?? "",
    },
    {
      field: "statData.consomationAnuelle",
      headerName: "Consommation Annuelle ADH",
      width: 180,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.statData?.consomationAnuelle?.toFixed(4) +
        " " +
        (params.row.clientData.pfProductsUnities.SalesUnity ?? ""),
    },
    {
      field: "statData.consomationAnuellePF",
      headerName: "Consommation Annuelle PF",
      width: 180,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.statData?.consomationAnuellePf?.toFixed(4) +
        " " +
        (params.row.clientData.ConvDataPf.SalesUnity ?? ""),
    },
    {
      field: "statData.totalQuantity",
      headerName: "Quantité achetée a la plateforme",
      width: 180,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.statData?.BuyingQuantityPf?.toFixed(4) +
        "/" +
        (params.row.statData?.BuyingQuantityTotal?.toFixed(4) ?? ""),
    },
    {
      field: "statData.QuantityPromotionMini",
      headerName: "Remise Quantitative Mini",
      width: 180,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.statData?.QuantityPromotionMini?.toFixed(4) +
        " " +
        (params.row.clientData.pfProductsUnities.SalesUnity ?? ""),
    },
    {
      field: "statData.TauxPromotionMini",
      headerName: "Taux de Remise Minii",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.statData?.TauxPromotionMini?.toFixed(2) + " %",
    },
    {
      field: "statData.QuantityPromotionMaxi",
      headerName: "Remise Quantitative Maxi",
      width: 180,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.statData?.QuantityPromotionMaxi?.toFixed(4) +
        " " +
        (params.row.clientData.pfProductsUnities.SalesUnity ?? ""),
    },
    {
      field: "statData.TauxPromotionMaxi",
      headerName: "Taux de Remise Maxi",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.statData?.TauxPromotionMaxi?.toFixed(2) + " %",
    },
    // {
    //   field: "statData.BuyingQuantityPf",
    //   headerName: "Quantité Achat PF",
    //   width: 150,
    //   hideable: true,
    //   sortable: false,
    //   valueGetter: (params) => params.row.statData?.BuyingQuantityPf?.toFixed(4) ?? "",
    // },

    {
      field: "repData.taxType",
      headerName: "Type de Taxe",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) => params.row.repData?.taxType ?? "",
    },
    {
      field: "repData.taxRate",
      headerName: "Taux de Taxe",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) => params.row.repData?.taxRate?.toFixed(2) ?? "",
    },
    // {
    //   field: "PF.salesAnualcli",
    //   headerName: "consomation anuelle (€) adhérent",
    //   width: 150,
    //   hideable: true,
    //   sortable: false,
    //   valueGetter: (params) =>
    //     params.row.clientData.pfProductBuyingPrice.AnnualSales ?? "",
    // }, //
  ];
  const CustomTooltip2 = ({ active, payload, label }: any) => {
    console.log("payload", payload);
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            background: "white",
            padding: 10,
            borderRadius: 5,
            border: "1px solid #ccc",
          }}
        >
          <h4 style={{ margin: 0, color: "#333" }}>
            {" "}
            {payload[0].payload.nameDisplay}
          </h4>
          <p style={{ margin: 0, color: "#ffd100" }}>
            Prix d'achat: {payload[0].value}€
          </p>
          <p style={{ margin: 0, color: "#040404" }}>
            Prix de vente: {payload[1].value}€
          </p>
        </div>
      );
    }
    return null;
  };
  // Custom tooltip component
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      console.log("payload", payload);
      const percentage = (
        (payload[0].value / parseInt(totalPages)) *
        100
      ).toFixed(2);
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p className="label">{`${payload[0].name} : ${percentage}%`}</p>
        </div>
      );
    }

    return null;
  };

  const isDisabled = !(
    gencod.trim().length === 13 || gencod.trim().length === 8
  );
  const buttonStyle = {
    color: isDisabled ? "gray" : "black",
    background: isDisabled ? "#FFEA7F" : "#FFD100", // Lighter shade when disabled
    cursor: isDisabled ? "not-allowed" : "pointer",
  };

  const CustomToolBar: any = () => {
    const handleExport = useCallback(() => {
      const roundTotalPages = Math.ceil(parseInt(totalPages) / 100);
      const pageSize = 100;

      const calculateEcartPFvsCLI = (
        pfSellingPrice: number,
        cliBuyingPrice: number
      ) => {
        if (cliBuyingPrice === 0) return 0;
        return ((cliBuyingPrice - pfSellingPrice) / pfSellingPrice) * 100;
      };
      const calculateRentabilite = (
        sellingPrice: number | string,
        comparisonPrice: number | string,
        cliDate: number | Date | null,
        pfDate: number | Date | null
      ) => {
        if (!cliDate || !pfDate || cliDate < pfDate) {
          return "Prix non actualisé";
        }

        // Parse sellingPrice and comparisonPrice to numbers
        const parsedSellingPrice = parseFloat(sellingPrice as string);
        const parsedComparisonPrice = parseFloat(comparisonPrice as string);

        // Check if parsing failed (NaN values)
        if (isNaN(parsedSellingPrice) || isNaN(parsedComparisonPrice)) {
          return "Non rentable"; // Handle invalid price values
        }

        // Perform the comparison
        if (parsedSellingPrice < parsedComparisonPrice) {
          return "Meilleur prix PF";
        } else if (parsedSellingPrice > parsedComparisonPrice) {
          return "Meilleur prix CLI";
        }

        return "Rentable"; // Prices are equal
      };

      const enrichData = (data: any) => {
        return data.map((item: any) => {
          const pfSellingPrice =
            parseFloat(item.clientData.pfProductPrice.ARVHTC) || 0;
          const cliBuyingPrice =
            parseFloat(item.clientData.pfProductBuyingPrice.ARPPXA) || 0;

          const cliDate = parseDate(
            item.clientData.pfProductBuyingPrice.ARPDPX
          );
          const pfDate = parseDate(item.clientData.pfProductPrice.ARVDEB);

          const ecartPFvsCLI = calculateEcartPFvsCLI(
            pfSellingPrice,
            cliBuyingPrice
          );
          const rentabilite = calculateRentabilite(
            pfSellingPrice,
            cliBuyingPrice,
            cliDate,
            pfDate
          );

          return {
            ...item,
            Rentabilite: rentabilite || "",
            EcartPFvsCLI:
              ecartPFvsCLI !== null ? ecartPFvsCLI.toFixed(2) + "%" : "",
          };
        });
      };

      const formatForExcel = (value: any) => {
        return value == null ? "" : value.toString();
      };

      const generateExcel = (data: any) => {
        const headers = [
          "type d'article",
          "Code EAN",
          "Prix de cession PF",
          "Prix d'achat CLI",
          "Rentabilité",
          "Écart PF vs CLI %",
          "Date d'application PF",
          "Date d'application CLI",
          "Code Article PF",
          "Code Société PF",
          "Référence Fournisseur PF",
          "Fournisseur PF",
          "Libellé 1 PF",
          "Libellé 2 PF",
          "Unité d'achat PF",
          "Prix d'achat brut PF",
          "Prix d'achat net PF",
          "Unité de vente PF",
          "Code Article CLI",
          "Code Société CLI",
          "Référence Fournisseur CLI",
          "Libellé 1 CLI",
          "Libellé 2 CLI",
          "Unité d'achat CLI",
          "Prix d'achat brut CLI",
          "Fournisseur CLI",
          "Stock disponible",
          "Consommation Annuelle CLI",
          "Quantité achetée à la plateforme",
          "Quantité Promotion Mini",
          "Taux Promotion Mini",
          "Quantité Promotion Maxi",
          "Taux Promotion Maxi",
          "Type de Taxe",
          "Taux de Taxe",
          "Prix de vente moyen CLI",
          "Prix d'achat moyen CLI",
          "Top Concordance",
        ];

        const wsData = [
          headers,
          ...data.map((item: any) => {
            const platform = item.clientData;
            const client = item.clientData;
            var valuetype = "";

            // Access the value directly from the row (item)
            const artcod = item.clientData.pfProductCodes.ARTCOD;
            if (artcod.startsWith("99D")) {
              valuetype = "direct";
            } else if (artcod.startsWith("9E")) {
              valuetype = "échantillon";
            } else if (/^K\d+$/.test(artcod)) {
              valuetype = "kit";
            } else if (/^K[A-Z]+$/.test(artcod)) {
              valuetype = "composant kit";
            } else {
              if (
                item.clientData.pfProductCodes.ARTPRM === "" &&
                item.clientData.pfProductCodes.ARTSAI === "O"
              ) {
                valuetype = "standard";
              } else if (
                item.clientData.pfProductCodes.ARTPRM === "C" &&
                item.clientData.pfProductCodes.ARTSAI === "N"
              ) {
                valuetype = "contremarque";
              } else {
                valuetype = "";
              }
            }
            // Return empty string or a default value if none of the conditions match

            // Format the value for Excel
            return [
              formatForExcel(item.second_DB),
              formatForExcel(item.gencod),
              formatForExcel(platform.pfProductPrice.SalePrice),
              formatForExcel(client.pfProductBuyingPrice.BuyingPrice),

              formatForExcel(platform.pfProductPrice.ApplicationDate),
              formatForExcel(client.pfProductBuyingPrice.ApplicationDate),
              formatForExcel(platform.pfProductCodes.ProductCode),
              formatForExcel(platform.pfProductCodes.Socity),
              formatForExcel(platform.pfProductCodes.ReffSupplier),
              formatForExcel(platform.pfProductCodes.SupplierName),
              formatForExcel(platform.pfProductsNames.ProductName),
              formatForExcel(platform.pfProductsNames.ProductName2),
              formatForExcel(platform.pfProductsUnities.BuyingUnity),
              formatForExcel(platform.pfProductBuyingPrice.GrossBuyingPrice),
              formatForExcel(platform.pfProductBuyingPrice.BuyingPrice),
              formatForExcel(platform.pfProductsUnities.SalesUnity),
              formatForExcel(client.pfProductCodes.ProductCode),
              formatForExcel(client.pfProductCodes.Socity),
              formatForExcel(client.pfProductCodes.ReffSupplier),
              formatForExcel(client.pfProductsNames.ProductName),
              formatForExcel(client.pfProductsNames.ProductName2),
              formatForExcel(client.pfProductsUnities.BuyingUnity),
              formatForExcel(client.pfProductBuyingPrice.GrossBuyingPrice),
              formatForExcel(client.pfProductCodes.SupplierName),
              formatForExcel(
                client.statData?.AvaibleStock?.toFixed(2) +
                  " " +
                  (client.pfProductsUnities.StockUnity ?? "")
              ),
              formatForExcel(
                client.statData?.consomationAnuelle?.toFixed(4) +
                  " " +
                  (client.pfProductsUnities.SalesUnity ?? "")
              ),
              formatForExcel(
                client.statData?.BuyingQuantityPf?.toFixed(4) +
                  "/" +
                  (client.statData?.BuyingQuantityTotal?.toFixed(4) ?? "")
              ),
              formatForExcel(
                client.statData?.QuantityPromotionMini?.toFixed(4) +
                  " " +
                  (client.pfProductsUnities.SalesUnity ?? "")
              ),
              formatForExcel(
                client.statData?.TauxPromotionMini?.toFixed(2) + " %"
              ),
              formatForExcel(
                client.statData?.QuantityPromotionMaxi?.toFixed(4) +
                  " " +
                  (client.pfProductsUnities.SalesUnity ?? "")
              ),
              formatForExcel(
                client.statData?.TauxPromotionMaxi?.toFixed(2) + " %"
              ),
              formatForExcel(client.repData?.taxType),
              formatForExcel(client.repData?.taxRate?.toFixed(2)),
              formatForExcel(
                parseFloat(client.statData?.SalesPriceAverage).toFixed(2) + "€"
              ),
              formatForExcel(
                parseFloat(client.statData?.BuyingPricesAverage).toFixed(2) +
                  "€"
              ),
              formatForExcel(client.statData?.topConcordance ?? ""),
            ];
          }),
        ];

        const ws = XLSX.utils.aoa_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, "comparateur_de_prix_par_adh.xlsx");
      };

      const exportToExcel = (data: any) => {
        const enrichedData = data;
        generateExcel(enrichedData);
        setLoading(false);
        exportToExcel(enrichedData);
      };
    }, [totalPages, selectedEndpoint, selectedClientBase]);

    return (
      <GridToolbarContainer>
        {/* <LoadingButton
          onClick={handleExport}
          loading={loading}
          loadingPosition="start"
          startIcon={<SaveAlt />}
          variant="text"
        >
          <span style={{ marginTop: 5 }}>Export XLSX</span>
        </LoadingButton> */}

        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Comparaison prix adhérents
          </Typography>
        </Box>
      </GridToolbarContainer>
    );
  };
  return (
    <MiniDrawer width={widthDrawer} setWidth={setWidthDrawer}>
      <Box style={{ flexGrow: 1, padding: "85px 0px 0px 32px" }}>
        <div style={{ flexGrow: 1, alignItems: "center", width: widthDrawer }}>
          {/* Header / breadcrums  */}
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Accueil
            </Link>
            <Typography color="text.primary">
              Comparateur de prix par adhérent
            </Typography>
          </Breadcrumbs>
          {/* title  */}
          <Typography
            variant="h3"
            style={{
              margin: "10px 10px 10px 0",
              fontWeight: "bold",
              fontSize: "32px",
            }}
          >
            Comparateur de prix par adhérent
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "23px",
            }}
          >
            <Stack direction="row" style={{ gap: "10px" }}>
              <FormControl style={{}}></FormControl>
              <TextField
                label="Code EAN ou code PF"
                variant="outlined"
                value={gencod}
                onChange={handleGencodChange}
                error={errorGencod}
                helperText={errorMessage}
                size="small"
              />

              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.9 }}
              >
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                  style={buttonStyle}
                  sx={{ maxHeight: "40px" }}
                  disabled={isDisabled}
                >
                  Comparer &nbsp;
                  <GrCompare />
                </Button>
              </motion.div>
            </Stack>
          </div>
        </div>
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-start",
          padding: "0 0 0 25px",
          margin: "20px",
        }}
      ></Box>

      {error ? (
        <Typography variant="body1" style={{ color: "red", margin: "20px" }}>
          {error}
        </Typography>
      ) : (
        priceComparisonResult.length > 0 && (
          <div
            style={{
              display: "flex",

              justifyContent: "center",
              flexDirection: "column",
              padding: "0 0 0 35px",
              width: widthDrawer,
            }}
          >
            {showCharts && !loading && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Card style={{ margin: "20px 0px", height: "400px" }}>
                      <CardHeader
                        title="Comparaison par EAN"
                        titleTypographyProps={{
                          variant: "h6",
                          textAlign: "center",
                        }}
                        style={{
                          backgroundColor: "#f5f5f5",
                          margin: "8px",
                          padding: "5px",
                          borderRadius: "5px",
                        }}
                      />
                      <CardContent></CardContent>
                    </Card>
                  </Grid>

                  {priceComparisonResult.length !== 1 &&
                    selectedEndpoint === "" &&
                    !loading && (
                      <Grid item xs={12} md={4}>
                        <Card style={{ margin: "20px 0px", height: "400px" }}>
                          <CardHeader
                            title="Pourcentage des prix plateforme et prix adhérents"
                            titleTypographyProps={{
                              variant: "h6",
                              textAlign: "center",
                            }}
                            style={{
                              backgroundColor: "#f5f5f5",
                              margin: "8px",
                              padding: "5px",
                              borderRadius: "5px",
                            }}
                          />

                          <CardContent>
                            <ResponsiveContainer width="100%" height={290}>
                              <PieChart>
                                <Tooltip content={<CustomTooltip />} />

                                <Legend
                                  verticalAlign="bottom"
                                  align="center"
                                  layout="vertical"
                                  wrapperStyle={{ paddingLeft: "20px" }}
                                />
                              </PieChart>
                            </ResponsiveContainer>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}

                  {/* New Pie Chart */}
                  {priceComparisonResult.length !== 1 && (
                    <Grid item xs={12} md={4}>
                      <Card style={{ margin: "20px 0px", height: "400px" }}>
                        <CardHeader
                          title="Pourcentage des articles achetés sur plateforme"
                          titleTypographyProps={{
                            variant: "h6",
                            textAlign: "center",
                          }}
                          style={{
                            backgroundColor: "#f5f5f5",
                            margin: "8px",
                            padding: "5px",
                            borderRadius: "5px",
                          }}
                        />
                        <CardContent></CardContent>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
            {chartData.length !== 0 && (
              <>
                <h2>Écart de Prix</h2>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={chartData}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip2 />} />
                    <Bar
                      dataKey="prix_achat"
                      fill="#ffd100"
                      name="Prix d'achat"
                    />
                    <Bar
                      dataKey="prix_vente"
                      fill="#040404"
                      name="Prix de vente"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </>
            )}
            <Grid container marginRight={"25px"} paddingBottom={"20px"}>
              <Grid item xs={12}>
                <div
                  style={{
                    height: "80vh",
                    overflow: "auto",
                  }}
                >
                  {" "}
                  <DataGrid
                    apiRef={apiRef}
                    sx={{
                      "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                      },
                      maxWidth: isSmallScreen ? "89.8vw" : "93.2vw",
                      width: isSmallScreen ? "100%" : "auto", //

                      "& .MuiDataGrid-columnHeaders": {
                        position: "sticky",
                        top: 0,
                        backgroundColor: "white",
                        zIndex: 1000,
                      },

                      "@media (min-width: 2005px)": {
                        maxWidth: "93.5vw",
                      },
                      "@media (min-width: 2365px)": {
                        maxWidth: "94.5vw",
                      },
                      "@media (min-width: 2495px)": {
                        maxWidth: "94.7vw",
                      },
                      "@media (min-width: 2565px)": {
                        maxWidth: "94.9vw",
                      },
                    }}
                    rows={priceComparisonResult}
                    columns={columns}
                    autoPageSize
                    getRowId={(row) =>
                      `${row.gencod}-${row.second_DB}-${
                        row.id || Math.random()
                      }`
                    }
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 20, page: 0 },
                      },

                      columns: {
                        columnVisibilityModel: {
                          rentability: false,
                        },
                      },
                    }}
                    hideFooter={true}
                    paginationMode="server"
                    onPaginationModelChange={handlePaginationModelChange}
                    slots={{
                      toolbar: () => <CustomToolBar />,
                      pagination: PaginationDatagrid,
                    }}
                    localeText={
                      frFR.components.MuiDataGrid.defaultProps.localeText
                    }
                    disableRowSelectionOnClick
                    slotProps={{
                      toolbar: { csvOptions: { allColumns: false } },
                      pagination: {
                        itemsCount: totalRecords, // Total number of records
                        itemsTitle: "éléments",
                        itemsperpage: 20,
                        totalPages: parseInt(totalPagesRecord),
                        currentPage: currentPage + 1,
                        setCurrentPage: setCurrentPage, // Pass down the function

                        // Number of items per page
                      },
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        )
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </MiniDrawer>
  );
};

export default ComparePricesPerAdh;

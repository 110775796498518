import React, { useEffect, useState } from "react";
import { FormHelperText } from "@mui/material";
import { FaDownload } from "react-icons/fa";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "./CsvToJsonConverter.css";

interface Props {
  template: (data: any) => void;
  setRows: (data: any[]) => void;
  newFile: boolean;
  setNewFile: (data: boolean) => void;
  skipLines?: any;
}

const CsvToJsonConverter: React.FC<Props> = ({
  template,
  setRows,
  newFile,
  setNewFile,
  skipLines,
}) => {
  const [fileName, setFileName] = useState<string | null>(null);
  const [dragOver, setDragOver] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errorRows, setErrorRows] = useState<{ line: number; data: any }[]>([]);

  useEffect(() => {
    if (newFile === true) {
      setFileName(null);
      setRows([]);
      setNewFile(false);
    }
  }, [newFile]);

  const csvToJSON = (csv: string, objTemplate: Object, skipLines: any) => {
    const lines = csv.split(/\r?\n/);
    const validRows: any[] = [];

    lines.slice(skipLines).forEach((line, index) => {
      const values = line
        .split(";")
        .map((value) => value.replace(/"/g, "").trim());
      const obj: any = { id: index + skipLines + 1 }; // Numéro de ligne réel dans le fichier

      Object.keys(objTemplate).forEach((key, i) => {
        let value = decodeURIComponent(values[i] || "");
        obj[key] = value;
      });

      // Consider a row as empty if all fields except 'id' are empty
      const isEmptyRow = Object.keys(obj)
        .filter((key) => key !== "id")
        .every((key) => obj[key] === "");

      if (!isEmptyRow && isValidRow(obj, objTemplate)) {
        validRows.push(obj);
      } else if (!isEmptyRow) {
        errorRows.push({ line: obj.id, data: obj });
      }
    });

    return { validRows, errorRows };
  };

  const isValidRow = (row: any, template: any) => {
    const areKeyFieldsEmpty = row["code_adherent"] === "" || row["sku"] === "";

    if (areKeyFieldsEmpty) {
      return false;
    }

    return Object.keys(template).every((key) => row[key] !== "");
  };

  const handleFileUpload = (file: File) => {
    // Check if the uploaded file is an CSV file
    if (!file.name.endsWith(".csv")) {
      // Show error message
      setErrorMessage(
        "Oups ! Quelque chose ne va pas. Veuillez envoyer un fichier CSV."
      );
      return;
    }

    setErrorMessage(null);

    const reader = new FileReader();

    reader.onload = async (event) => {
      if (event.target && event.target.result) {
        const text = event.target?.result as string;
        const { validRows, errorRows } = csvToJSON(text, template, skipLines);

        console.log("Lignes valides:", validRows);
        console.log("Lignes avec erreurs:", errorRows);

        if (errorRows.length > 0) {
          setNewFile(true);
          setErrorRows(errorRows);
          setErrorMessage(
            `Le fichier contient ${errorRows.length} ligne(s) avec des champs manquants.`
          );
        } else {
          setErrorMessage(null);
          setRows(validRows);
          setErrorRows([]);
        }

        setFileName(file.name);
      } else {
        console.error("Échec de la lecture du fichier.");
      }
    };

    reader.readAsText(file, "UTF-8");
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);
    const file = event.dataTransfer.files?.[0];
    if (file) {
      if (!file.name.endsWith(".csv")) {
        setErrorMessage(
          "Oups ! Quelque chose ne va pas. Veuillez envoyer un fichier CSV."
        );
        return;
      } else {
        setErrorMessage(null);
        setFileName(file.name);
        handleFileUpload(file);
      }
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);
  };

  return (
    <div
      style={{
        margin: "16px 16px 16px 0px",
        width: `${fileName ? "auto" : "100%"}`,
      }}
    >
      <label htmlFor="inputFile" className="label-file">
        <div
          className={`drop-area ${dragOver ? "drag-over" : ""} ${
            fileName ? "file-uploaded" : ""
          }`}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {!fileName && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  height: "45vh",
                }}
              >
                <FaDownload style={{ fontSize: "24px", margin: "10px" }} />
                <p style={{ padding: "10px", display: "flex" }}>
                  Déposer votre fichier ici ou{" "}
                  <div className="input2">
                    {" "}
                    &nbsp; Cliquez pour selectionner{" "}
                  </div>
                </p>
                <input
                  id="inputFile"
                  type="file"
                  accept=".csv"
                  onChange={(event) => {
                    const file = event.target.files?.[0];
                    if (file) {
                      handleFileUpload(file);
                    }
                  }}
                  className="input-file"
                />
                {errorMessage && (
                  <div style={{ marginTop: "10px", color: "red" }}>
                    <FormHelperText
                      sx={{ display: "flex", alignItems: "center", gap: "5px" }}
                      error
                    >
                      <InfoOutlinedIcon />
                      {errorMessage}
                    </FormHelperText>

                    {errorRows.length > 0 && (
                      <div style={{ marginTop: "10px" }}>
                        <p>
                          <strong>Erreurs détectées :</strong>
                        </p>
                        <ul>
                          {errorRows.map((error, index) => (
                            <li key={index}>
                              Ligne {error.line}: {JSON.stringify(error.data)}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          )}

          {fileName && (
            <div className="file-name" style={{ display: "block" }}>
              Nom du fichier : {fileName}
            </div>
          )}
        </div>
      </label>
    </div>
  );
};

export default CsvToJsonConverter;

import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Link,
  Box,
  styled,
  ButtonProps,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  SelectChangeEvent,
  Card,
  CardContent,
  CardHeader,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
  useMediaQuery,
  Stack,
  Input,
} from "@mui/material";
import axios from "axios";
import MiniDrawer from "../../tools/MiniDrawer";
import { purple } from "@mui/material/colors";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  PieLabelRenderProps,
  TooltipProps,
  Label,
} from "recharts";
import {
  DataGrid,
  frFR,
  GridColDef,
  GridCsvExportOptions,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueGetterParams,
  nlNL,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import "./ComparePrices.css";
import axiosInstance from "../../../axiosInstance";
import { PaginationDatagrid } from "../../tools/PaginationDatagrid";
import fakeData from "../fakeData";
import { useMsal } from "@azure/msal-react";
import { Backdrop, CircularProgress } from "@mui/material";
import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";
import { SaveAlt } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Autocomplete } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { GrCompare } from "react-icons/gr";
import { enqueueSnackbar } from "notistack";
import * as XLSX from "xlsx";
import { motion } from "framer-motion";
import stringToColor from "string-to-color";
const ComparePrices = () => {
  const [loading, setLoading] = useState(false);
  //  const apiRef = useGridApiContext();
  const topScrollbarRef = useRef<HTMLDivElement | null>(null);
  const bottomScrollbarRef = useRef<HTMLDivElement | null>(null);
  const [priceComparisonResult, setPriceComparisonResult] = useState<any[]>([]);
  const [totalResultForCsv, setTotalResultForCsv] = useState<any[]>([]);
  const [stats, setStats] = useState(0);
  const [selectedClientBase, setSelectedClientBase] = useState("");
  const [selectedClientName, setSelectedClientName] = useState("");
  const [displayedClientName, setDisplayedClientName] = useState("");
  const [isBestPriceSelected, setIsBestPriceSelected] = useState(false);

  const [gencod, setGencod] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [totalPages, setPages] = useState("");
  const [page, setPage] = React.useState(1);
  const [wrongItems, setwrongItems] = React.useState(1);
  const [itemsBoughtOnPlateform, setitemsBoughtOnPlateform] = React.useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [roleError, setRoleError] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  const [role, setRole] = useState("");
  const [totalPagesRecord, setTotalPages] = useState("");
  const [tokenMsal, setTokenMsal] = useState<string | null>(null);
  const { instance, accounts, inProgress } = useMsal();
  const msalInstance = instance;
  const [showCharts, setShowCharts] = useState(() => {
    const storedShowCharts = localStorage.getItem("showCharts");
    return storedShowCharts !== null ? JSON.parse(storedShowCharts) : false;
  }); // State to control visibility of charts
  const isSmallScreen = useMediaQuery("(max-width:1300px)");
  const isBigScreen = useMediaQuery("(max-width:2500px)");
  const isSyncingRef = useRef(false); // To prevent infinite loops

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [idToken, setIdToken] = useState<string | null>(null);
  const [selectedEndpoint, setSelectedEndpoint] = useState("");
  const [widthDrawer, setWidthDrawer] = useState("");
  const [selectedOption, setSelectedOption] = useState("compareList");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedFilterOrderby, setSelectedFilterOrderby] = useState("ASC");
  const optionsForFilter = [
    { label: "fournisseur", value: "frn" },
    { label: "écart", value: "gap" },
    { label: "libellé", value: "lib" },
  ];

  const [validData, setValidData] = useState(false);
  const [errorGencod, setErrorGencod] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const apiRef = useGridApiRef();
  const handleRadioChange = (e: any) => {
    const value = e.target.value;
    apiRef.current.setPage(0);

    setSelectedOption(value);

    //handlePaginationModelChange({ page: 0, pageSize: 20 });
    // handleSubmit(value);
    if (["", "WRONG", "BETTERPF", "BETTERCLI"].includes(value)) {
      setTimeout(() => {
        handleSubmit(value, true).then(() => {});
      }, 400);

      // handlePaginationModelChange({ page: 0, pageSize: 20 });
      // setShowCharts(false); // Uncheck the switch when one of the last three radios is selected
    }
  };
  useEffect(() => {
    const handleTopScroll = (e: Event) => {
      if (isSyncingRef.current) return;
      isSyncingRef.current = true;
      if (bottomScrollbarRef.current) {
        bottomScrollbarRef.current.scrollLeft = (
          e.target as HTMLDivElement
        ).scrollLeft;
      }
      isSyncingRef.current = false;
    };

    const handleBottomScroll = (e: Event) => {
      if (isSyncingRef.current) return;
      isSyncingRef.current = true;
      if (topScrollbarRef.current) {
        topScrollbarRef.current.scrollLeft = (
          e.target as HTMLDivElement
        ).scrollLeft;
      }
      isSyncingRef.current = false;
    };

    if (topScrollbarRef.current) {
      topScrollbarRef.current.addEventListener("scroll", handleTopScroll);
    }

    if (bottomScrollbarRef.current) {
      bottomScrollbarRef.current.addEventListener("scroll", handleBottomScroll);
    }

    return () => {
      if (topScrollbarRef.current) {
        topScrollbarRef.current.removeEventListener("scroll", handleTopScroll);
      }
      if (bottomScrollbarRef.current) {
        bottomScrollbarRef.current.removeEventListener(
          "scroll",
          handleBottomScroll
        );
      }
    };
  }, []);
  const Legend2 = () => {
    const legendItems = [
      { label: "Direct", color: stringToColor("direct") },
      { label: "Échantillon", color: stringToColor("échantillon") },
      { label: "Kit", color: stringToColor("kit") },
      { label: "Composant Kit", color: stringToColor("composant kit") },
      { label: "Standard", color: stringToColor("standard") },
      { label: "Contremarque", color: stringToColor("contremarque") },
    ];

    return (
      <Box
        sx={{
          marginBottom: 0,
          display: "flex",
          paddingTop: "10px",
          flexDirection: "row",
          fontSize: "8px",
          width: "75%",
        }}
      >
        Type d'article:
        {legendItems.map((item, index) => (
          <Grid container key={index} alignItems="center" spacing={1}>
            <Grid item>
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  borderRadius: "50%",
                  backgroundColor: item.color,
                }}
              />
            </Grid>
            <Grid item>
              <Typography style={{ fontSize: "10px" }}>{item.label}</Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
    );
  };

  const isToggleDisabled = [
    "compareListBetterPricesPF",
    "compareListWrongPrices",
    "compareListBetterPricesCli",
  ].includes(selectedOption);
  console.log(accounts);
  const tokenRequest = {
    scopes: ["openid", "profile", "User.Read"],
    forceRefresh: true, // Force a token refresh
  };
  // Function to toggle visibility of charts
  const toggleCharts = () => {
    setShowCharts(!showCharts);
  };

  // Effect to save user preference in localStorage
  useEffect(() => {
    localStorage.setItem("showCharts", JSON.stringify(showCharts));
  }, [showCharts]);

  // Function to get stored user preference from localStorage
  useEffect(() => {
    const storedShowCharts = localStorage.getItem("showCharts");
    if (storedShowCharts !== null) {
      setShowCharts(JSON.parse(storedShowCharts));
    }
  }, []); // State to control visibility of charts
  useEffect(() => {
    setCurrentPage(0);
  }, [selectedEndpoint]); // State to control visibility of charts

  const getToken = async (account: AccountInfo) => {
    try {
      const response = await msalInstance.acquireTokenSilent({
        ...tokenRequest,
        account,
      });
      console.log(response);
      return response.idToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // If interaction is required, fallback to an interactive method
        const response = await msalInstance.acquireTokenPopup(tokenRequest);
        console.log(response);

        return response.idToken;
      } else {
        console.log("error token ");
        throw error;
      }
    }
  };
  // Temporary vars for dev
  let statError: any;
  //let roleError: any = fakeData.fakerole;
  let reponseError: any;
  let paginationError: any = 1;
  useEffect(() => {
    const fetchRole = async () => {
      if (accounts.length > 0 && inProgress === "none") {
        try {
          const token = await getToken(accounts[0]);
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          const response = await axios.get(
            `https://api.prod.gcp.toutfaire.com/v1/gw/v1/apitools/users/${accounts[0].username}`
          );
          response.data.out_data.PriceComparatorRights.databases.forEach(
            (d: any) => {
              if (d.clientName === "") d.clientName = d.databaseName;
            }
          );
          setRoleError(response.data.out_data.PriceComparatorRights.databases);
          if (
            response.data.out_data.PriceComparatorRights.databases.length ===
              0 ||
            response.data.out_data.userRole === ""
          )
            localStorage.setItem("RightPriceComparator", "false");
        } catch (error) {
          console.error("Error fetching role:", error);
          setRoleError([]);
        }
      }
    };

    fetchRole();
  }, [accounts, inProgress]);
  // utils functions  set base client with select
  const handleClientBaseChange = (event: any, value: any) => {
    setValidData(false);
    setSelectedOption("compareList");

    console.log("enene", event.target);
    console.log("enene", value);
    if (!value) {
      setSelectedClientBase("");
      setSelectedClientName("");
    }
    if (event.target.tagName === "INPUT") {
      console.log("enene", event.target.value);
      setSelectedClientBase(value.databaseName);
      setSelectedClientName(value.clientName);
    } else if (event.target.tagName === "LI") {
      console.log("enene", event.target.firstChild.nodeValue);
      setSelectedClientBase(value.databaseName);
      setSelectedClientName(value.clientName);
    }
    // handleSubmit();
  };
  //

  const getTokenMsal = async () => {
    setTokenMsal(await getToken(accounts[0]));
  };
  const handlePaginationModelChange = async (newModel: any) => {
    // Start loading
    console.log("new", newModel);
    setPaginationModel(newModel);
    //setTokenMsal(idToken);
    // let tokenMsal = instance?.getAllAccounts()[0].idToken ?? "";

    //console.log("instance", tokenMsal);
    let token = await getToken(instance?.getAllAccounts()[0]);
    axios.defaults.headers.common["Authorization"] = `Bearer ${token ?? ""}`;
    const pageNumber = newModel.page + 1;
    //console.log("test token", tokenMsal);
    if (gencod === "") {
      setLoading(true);
      try {
        const encodedURI = `https://api.prod.gcp.toutfaire.com/v1/gw/v1/apitools/pricecomparator/compareAll?database=${encodeURIComponent(
          selectedClientBase
        )}&pageNumber=${pageNumber}&feature=${selectedEndpoint}&orderBy=${selectedFilterOrderby}&fieldToOrder=${selectedFilter}`;
        const response = await axios
          .get(encodedURI, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .finally(() => {
            setLoading(false); // Start loading
          });
        // any type for dev
        const dataWithIds = response.data.out_data.articles.map(
          (item: any, index: number) => {
            // Check if item.plateformData.pfProductsUnities.CVSUA is not equal to item.plateformData.pfProductsUnities.CVSUV
            if (
              item.clientData.pfProductsUnities.CVSUA !==
              item.plateformData.pfProductsUnities.CVSUV
            ) {
              console.log("conversion");
              // Perform the calculation: ARVHTC = (ARVHTC * CVSUV) / CVSUA
              // item.plateformData.pfProductPrice.ARVHTC =
              //   (item.plateformData.pfProductPrice.ARVHTC *
              //     item.plateformData.pfProductsUnities.CVSUSUV) /
              //   item.clientData.pfProductsUnities.CVSUAUS;
              console.log(
                "conversion data",
                item.plateformData.pfProductPrice,
                item.plateformData.pfProductsUnities.CVSUSUV,
                item.clientData.pfProductsUnities.CVSUAUS
              );
            }

            return {
              ...item,
              id: index + 1, // Generates unique IDs starting from 1
            };
          }
        );

        setPriceComparisonResult(dataWithIds);

        setStats(response.data.out_data.stats.whereClientPriceIsBetter);
        setwrongItems(response.data.out_data.stats.whereClientPriceIsWrong);
        if (selectedEndpoint === "") {
          setPages(response.data.out_data.pagination.totalRecords);
          setitemsBoughtOnPlateform(
            response.data.out_data.stats.itemsBoughtOnPlateform
          );
        } else if (itemsBoughtOnPlateform === 1) {
          setLoading(true); // Start loading

          const encoddURI = `https://api.prod.gcp.toutfaire.com/v1/gw/v1/apitools/pricecomparator/compareAll?database=${encodeURIComponent(
            selectedClientBase
          )}&pageNumber=${pageNumber}`;
          const response = await axios
            .get(encoddURI, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .finally(() => {
              setLoading(false); // Start loading
            });
          // any type for dev
          setPages(response.data.out_data.pagination.totalRecords);
          setitemsBoughtOnPlateform(
            response.data.out_data.stats.itemsBoughtOnPlateform
          );
        }
        let total = parseInt(response.data.out_data.pagination.totalPages) - 1;
        setTotalRecords(response.data.out_data.pagination.totalRecords);
        setTotalPages(total.toString());
        setCurrentPage(response.data.out_data.pagination.currentPage);

        setPaginationModel({
          page: response.data.out_data.pagination.currentPage,
          pageSize: 20,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    if (gencod !== "") {
      setSelectedOption("compareList");

      //setSelectedEndpoint("compareList");
      //setValidData(true);
      //setErrorGencod(false);
      //setErrorMessage("");
    }
  }, [gencod]);

  useEffect(() => {
    if (errorMessage !== "") {
      //setValidData(true);
      //setErrorGencod(false);
      //setErrorMessage("");
    }
  }, [gencod]);
  const handleGencodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setValidData(false);
    if (errorGencod) {
      setErrorGencod(false);
      setErrorMessage("");
    }

    setGencod(event.target.value);
  };
  //const parseDate = (dateString:string) => new Date(dateString); // Assuming this is your date parsing function
  // data for the pie chart (data where pf better than cli)
  const getPieChartData = () => {
    let nonMatchCount = stats;
    let earlierDateCount = wrongItems;

    let matchCount = parseInt(totalPages) - (stats + wrongItems);
    console.log("pie", nonMatchCount, matchCount, earlierDateCount);

    priceComparisonResult.forEach((item) => {
      const platformPrice = item.plateformData.pfProductPrice.ARVHTC;
      const clientPrice = item.clientData.pfProductBuyingPrice.ARPPXA;
      const cliDate = parseDate(item.clientData.pfProductBuyingPrice.ARPDPX);
      const pfDate = parseDate(item.plateformData.pfProductPrice.ARVDEB);
      if (cliDate!! < pfDate!!) {
        //earlierDateCount++;
      } else if (platformPrice <= clientPrice) {
        // matchCount++;
      } else if (platformPrice > clientPrice) {
        // nonMatchCount++;
      }
    });

    return [
      { name: "Prix plateforme meilleur", value: matchCount },
      { name: "Prix adhérent meilleur", value: nonMatchCount },
      { name: "Prix adhérent non-actualisé", value: earlierDateCount },
    ];
  };
  //
  const calculateMatchingData = () => {
    let matchingCount = itemsBoughtOnPlateform;
    let nonMatchingCount = parseInt(totalPages) - itemsBoughtOnPlateform;

    return [
      { name: " % Achetés sur la plateforme", value: matchingCount },
      { name: " % Achetés ailleurs", value: nonMatchingCount },
    ];
  };
  const matchingData = calculateMatchingData();

  const retriveRole = async () => {
    let role = "admin";

    return role;
  };

  const handleSubmit = async (
    selectedEndpoint: string = "",
    fromswitch = false
  ) => {
    //await getTokenMsal();
    let token = await getToken(instance?.getAllAccounts()[0]);
    axios.defaults.headers.common[
      "Authorization"
      //  ] = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ik1HTHFqOThWTkxvWGFGZnBKQ0JwZ0I0SmFLcyJ9.eyJhdWQiOiJlNTk0Zjg1ZC04YzljLTQ3YzEtOGM3ZC0xMGYxMGNlNWYwZTIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vN2VlZTYyYTEtMDA1Mi00YWU2LTlkNzUtMzJhYTVlOWM1NzMzL3YyLjAiLCJpYXQiOjE3MTk0ODk2MjUsIm5iZiI6MTcxOTQ4OTYyNSwiZXhwIjoxNzE5NDkzNTI1LCJuYW1lIjoiSnVsaWVuIEzDqWdlciIsIm5vbmNlIjoiMDE5MDU5OTUtZjhhMi03NGU4LWFkNTctZjc3M2I0Y2MyMDYyIiwib2lkIjoiMzc5NTNlZDEtYTI3Ny00NDRkLTkwMTItY2NkZjFiZDAzZmFjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiamxlZ2VyQHRvdXRmYWlyZS5mciIsInJoIjoiMC5BVWNBb1dMdWZsSUE1a3FkZFRLcVhweFhNMTM0bE9XY2pNRkhqSDBROFF6bDhPSU5BUzQuIiwic3ViIjoiUFluZzdVVWhMeDZ1VW9nOGdLLWdGOGptYUdpQnVXeXJfdlNhcm1pWXZHdyIsInRpZCI6IjdlZWU2MmExLTAwNTItNGFlNi05ZDc1LTMyYWE1ZTljNTczMyIsInV0aSI6IjZvRFBaZzNlMzB1T3RoMkNxLXU3QUEiLCJ2ZXIiOiIyLjAifQ.dzQEgJj4KrUxlcGDuZ5maE7nOaNw-0FxlU1Gn6qnHZju7xI2xD8tp7OFyNqJkNzNJ-TS6gE4qKaQzMIdbFo5oG0b8svC8ks7atM7QwpKUgqGjHZ70opLXVzJvByeSgWvufPSmzyHq19BjUrIKO09MDKXq5y7-YK-IXeQZMix2WLhdUv7B2ZKVEvifubYPFj0m9M4xzqHdAWeF9aacDfjE-zACGy0n8cSUZbIbrE3tM_y5ALlBO71KjwnvNxRXXkvSdwx1P1CqI63wd98hgGjv6W4GB5764XQcG3aVMpnshzxgzH694ElQSnlMYPv5YKQXENQZw3jn3ItLG0HECKIjw
      //`;
    ] = `Bearer ${token ?? ""}`;
    setSelectedEndpoint(selectedEndpoint);
    setSelectedOption(selectedEndpoint);

    try {
      retriveRole().then((role) => {
        setRole(role);
      });
    } catch {}

    setLoading(true); // Start loading

    //TODO: si gencod='' faire compareList
    //
    //console.log("test token", tokenMsal);

    try {
      if (gencod !== "") {
        //let tokenMsal = instance?.getAllAccounts()[0].idToken ?? "";
        //console.log("instance", tokenMsal);
        setValidData(false);

        const response = await axios
          .get(
            "https://api.prod.gcp.toutfaire.com/v1/gw/v1/apitools/pricecomparator/" +
              gencod +
              "?database=" +
              selectedClientBase,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .finally(() => {
            setLoading(false); // Start loading
          });
        const dataWithIds: any = [];
        response.data.out_ComparedPrices.id = 1;
        dataWithIds.push(response.data.out_ComparedPrices);
        console.log("yooo", dataWithIds);
        if (response.data.out_code === "000") {
          setPriceComparisonResult(dataWithIds);

          setPages("1");
          setTotalPages("1");

          setPaginationModel({
            page: 0,
            pageSize: 5,
          });

          setitemsBoughtOnPlateform(1);
          // setStats(0);
          setTotalRecords(1);
          setCurrentPage(1);
          setDisplayedClientName(selectedClientName);
          setwrongItems(0);
          setPages("1");
          setTotalPages("1");
          if (
            dataWithIds[0].clientData.pfProductBuyingPrice.ARPDPX <
            dataWithIds[0].plateformData.pfProductPrice.ARVDEB
          ) {
            setwrongItems(1);
          } else if (
            dataWithIds[0].clientData.pfProductBuyingPrice.ARPPXA <=
            dataWithIds[0].plateformData.pfProductPrice.ARVHTC
            // article error
            //clientData.pfProductBuyingPrice.ARPDPX<arvdeb
          ) {
            setStats(0);
          } else {
            setStats(1);
          }
        } else {
          setErrorGencod(true);
          setErrorMessage("code EAN inconnu");
          setValidData(true); // enqueueSnackbar("code EAN inconnu", {
          //   variant: "error",
          //   autoHideDuration: 3500,
          // });
        }
      } else {
        // let tokenMsal = instance?.getAllAccounts()[0].idToken ?? "";
        //console.log("instance", tokenMsal);

        const response = await axios
          .get(
            `https://api.prod.gcp.toutfaire.com/v1/gw/v1/apitools/pricecomparator/compareAll?database=` +
              selectedClientBase +
              "&pageNumber=" +
              page +
              "&feature=" +
              selectedEndpoint +
              "&orderBy=" +
              selectedFilterOrderby +
              "&fieldToOrder=" +
              selectedFilter,

            {
              headers: {
                "Content-Type": "application/json",

                // baggage:
                //   `sentry-environment=production,sentry-public_key=d3c8b997e681c3f731afea15ee357828,sentry-trace_id=${Sentry.},sentry-sample_rate=1,sentry-sampled=false`
                //Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6InE3UDFOdnh1R1F3RE4yVGFpTW92alo4YVp3cyJ9.eyJhdWQiOiJlNTk0Zjg1ZC04YzljLTQ3YzEtOGM3ZC0xMGYxMGNlNWYwZTIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vN2VlZTYyYTEtMDA1Mi00YWU2LTlkNzUtMzJhYTVlOWM1NzMzL3YyLjAiLCJpYXQiOjE3MTg2OTMxMTEsIm5iZiI6MTcxODY5MzExMSwiZXhwIjoxNzE4Njk3MDExLCJuYW1lIjoiSnVsaWVuIEzDqWdlciIsIm5vbmNlIjoiMDE5MDJhMWMtMzczNy03OGYxLTkzOTAtNDk2OTg0YjFkOGI0Iiwib2lkIjoiMzc5NTNlZDEtYTI3Ny00NDRkLTkwMTItY2NkZjFiZDAzZmFjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiamxlZ2VyQHRvdXRmYWlyZS5mciIsInJoIjoiMC5BVWNBb1dMdWZsSUE1a3FkZFRLcVhweFhNMTM0bE9XY2pNRkhqSDBROFF6bDhPSU5BUzQuIiwic3ViIjoiUFluZzdVVWhMeDZ1VW9nOGdLLWdGOGptYUdpQnVXeXJfdlNhcm1pWXZHdyIsInRpZCI6IjdlZWU2MmExLTAwNTItNGFlNi05ZDc1LTMyYWE1ZTljNTczMyIsInV0aSI6IlZxNlVIa29yQWt5RFFwWExUbkFMQUEiLCJ2ZXIiOiIyLjAifQ.G_MROJgNzoo4j7DAdBjEYgWiKNu49qd1h5w7HZLjZZEZsoy3ETzhpfNGUgPlgqsFg_pJUEV8PRJJHUDq6FKEgczsyUbXjRymjWNTdTHc8mZS7KNnCys2dMIpYmSjaKMs5RX8L_gxzii0lFjbqKSZfxkz3qtCCCEJM_Wiqk8A89pax5R-adolQBe25qN2ti3IpdlFdvk0Y3owNWogg_W3VcCN4t3RRsm1nG50nSAAXul-BBbIQwymzCpmKvfMhtljDJnWCfYxBKSM7XHW_A0d3T_B6WGSnYzmF0vJlHXOtvpPm7_sHxRbDWrCQWNtV3ssJpkgiSOPMheEbD_sVuaPag`,
              },
            }
          )

          .finally(() => {
            setLoading(false); // Start loading
          });
        console.log("res", response);

        const dataWithIds = response.data.out_data.articles.map(
          (item: any, index: number) => {
            // CASE
            //     WHEN t8.cvsusuv = t8.cvsuaus
            //      THEN COALESCE((ABS((t6.arppxa) - t5.arvhtc) / NULLIF(t5.arvhtc, 0)) * 100, 0)
            // ELSE COALESCE((ABS((t6.arppxa / t7.cvsuaus) - t5.arvhtc) / NULLIF(t5.arvhtc, 0)) * 100, 0)
            // Check if item.plateformData.pfProductsUnities.CVSUA is not equal to item.plateformData.pfProductsUnities.CVSUV
            if (
              item.plateformData.pfProductsUnities.CVSUAUS !==
              item.plateformData.pfProductsUnities.CVSUSUV
            ) {
              // Perform the calculation: ARVHTC = (ARVHTC * CVSUV) / CVSUA
              item.clientData.pfProductBuyingPrice.ARPPXA =
                item.clientData.pfProductBuyingPrice.ARPPXA /
                item.clientData.pfProductsUnities.CVSUAUS;
            }

            return {
              ...item,
              id: index + 1, // Generates unique IDs starting from 1
            };
          }
        );
        setTotalRecords(response.data.out_data.pagination.totalRecords);

        setPriceComparisonResult(dataWithIds);
        setDisplayedClientName(selectedClientName);

        setStats(response.data.out_data.stats.whereClientPriceIsBetter);
        setwrongItems(response.data.out_data.stats.whereClientPriceIsWrong);
        if (selectedEndpoint === "") {
          setPages(response.data.out_data.pagination.totalRecords);
          setitemsBoughtOnPlateform(
            response.data.out_data.stats.itemsBoughtOnPlateform
          );
        } else if (itemsBoughtOnPlateform === 1) {
          setLoading(true);
          const encoddURI = `https://api.prod.gcp.toutfaire.com/v1/gw/v1/apitools/pricecomparator/compareAll?database=${encodeURIComponent(
            selectedClientBase
          )}&pageNumber=${page}`;
          const response = await axios
            .get(encoddURI, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .finally(() => {
              if (fromswitch) {
                apiRef.current.setPage(0);
              }
              setLoading(false); // Start loading
            });
          // any type for dev
          setPages(response.data.out_data.pagination.totalRecords);
          setitemsBoughtOnPlateform(
            response.data.out_data.stats.itemsBoughtOnPlateform
          );
        }
        let total = parseInt(response.data.out_data.pagination.totalPages) - 1;
        setCurrentPage(response.data.out_data.pagination.currentPage);

        console.log("total", total);
        setTotalPages(total.toString());
        //setCurrentPage(0);
        // apiRef.current.setPage(0);
        setPaginationModel({
          page: currentPage,
          pageSize: 20,
        });
        setValidData(true);

        //apiRef.current.setPage(0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);

      // setError("Error fetching data. Showing fallback data.");
      // Show fallback data
      //  roleError = fakeData.fakerole;
      statError = fakeData.fakeStat;
      paginationError = fakeData.fakePagination;
      reponseError = fakeData.fakearticles;

      const dataWithIds = reponseError.map((item: any, index: any) => ({
        ...item,
        id: index + 1, // Generates unique IDs starting from 1
      }));
      setStats(statError.whereClientPriceIsBetter);
      setDisplayedClientName(selectedClientName);

      setwrongItems(statError.whereClientPriceIsWrong);
      setitemsBoughtOnPlateform(statError.itemsBoughtOnPlateform);
      setPriceComparisonResult(dataWithIds);
      setTotalRecords(paginationError.totalRecords);
      setPaginationModel({
        page: currentPage,
        pageSize: 20,
      });
      setPages(paginationError.totalRecords);
      setTotalPages(paginationError.totalPages);
    }
  };

  const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "#FFD100",
    "&:hover": {
      backgroundColor: "#FFD100",
    },
    borderRadius: "32px",
    textTransform: "none",
  }));
  const greenPercentage = () => {
    const total = getPieChartData().reduce(
      (sum, entry) => sum + entry.value,
      0
    );
    const greenEntry = getPieChartData().find(
      (entry) => entry.name === "Prix plateforme meilleur"
    ); // Use the correct key for green items
    console.log("green enty ", greenEntry, total);
    if (greenEntry && total > 0) {
      return ((greenEntry.value / total) * 100).toFixed(2);
    }

    return "0.00"; // Return 0.00 if there's an issue
  };
  const parseDate = (dateStr: string): Date | null => {
    if (!dateStr) return null;

    // Check if the date string is in yyyy-mm-dd format
    const isoFormat = /^\d{4}-\d{2}-\d{2}$/;
    if (isoFormat.test(dateStr)) {
      // Parse yyyy-mm-dd format
      const [year, month, day] = dateStr
        .split("-")
        .map((part) => parseInt(part, 10));
      return new Date(year, month - 1, day);
    } else {
      // Assuming default format is dd/mm/yyyy
      const [day, month, year] = dateStr
        .split("/")
        .map((part) => parseInt(part, 10));
      return new Date(year, month - 1, day);
    }
  };

  const getRentability = (params: GridValueGetterParams<any, any>): string => {
    const sellingPrice = params.row.plateformData.pfProductPrice.ARVHTC;
    const comparisonPrice = params.row.clientData.pfProductBuyingPrice.ARPPXA;
    console.log("test", params.row);
    const cliDate = parseDate(
      params.row.clientData.pfProductBuyingPrice.ARPDPX
    );
    const pfDate = parseDate(params.row.plateformData.pfProductPrice.ARVDEB);

    if (!cliDate || !pfDate || cliDate < pfDate) {
      return "Prix non actualisé";
    }

    if (sellingPrice && comparisonPrice) {
      if (sellingPrice < comparisonPrice) {
        return "Meilleur prix PF";
      } else if (sellingPrice > comparisonPrice) {
        return "Meilleur prix CLI";
      } else {
        return "Rentable";
      }
    }

    return "Non rentable";
  };

  // Creating columns for the data grid
  const columns: GridColDef[] = [
    {
      field: "type Product",
      headerName: "Type article",
      width: 100,
      sortable: false,
      valueGetter: (params) => {
        const artcod = params.row.plateformData.pfProductCodes.ARTCOD;
        if (artcod.startsWith("99D")) {
          return "direct";
        } else if (artcod.startsWith("9E")) {
          return "échantillon";
        } else if (/^K\d+$/.test(artcod)) {
          return "kit";
        } else if (/^K[A-Z]+$/.test(artcod)) {
          return "composant kit";
        }
        if (
          params.row.plateformData.pfProductCodes.ARTPRM === "" &&
          params.row.plateformData.pfProductCodes.ARTSAI === "O"
        ) {
          return "standard";
        } else if (
          params.row.plateformData.pfProductCodes.ARTPRM === "C" &&
          params.row.plateformData.pfProductCodes.ARTSAI === "N"
        ) {
          return "contremarque";
        }
        return ""; // Return empty string or a default value if none of the conditions match
      },
      renderCell: (params: any) => {
        if (params.row.plateformData.pfProductCodes.ARTCOD.startsWith("99D")) {
          return (
            <div
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: stringToColor("direct"),
                display: "inline-block",
              }}
              title="direct"
            ></div>
          );
        } else if (
          params.row.plateformData.pfProductCodes.ARTCOD.startsWith("9E")
        ) {
          return (
            <div
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: stringToColor("échantillon"),
                display: "inline-block",
              }}
              title="échantillon"
            ></div>
          );
        } else if (
          /^K\d+$/.test(params.row.plateformData.pfProductCodes.ARTCOD)
        ) {
          return (
            <div
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: stringToColor("kit"),
                display: "inline-block",
              }}
              title="kit"
            ></div>
          );
        } else if (
          /^K[A-Z]+$/.test(params.row.plateformData.pfProductCodes.ARTCOD)
        ) {
          return (
            <div
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: stringToColor("composant kit"),
                display: "inline-block",
              }}
              title="composant kit"
            ></div>
          );
        }
        if (
          params.row.plateformData.pfProductCodes.ARTPRM == "" &&
          params.row.plateformData.pfProductCodes.ARTSAI == "O"
        )
          return (
            <div
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: stringToColor("standard"),
                display: "inline-block",
              }}
              title="standard"
            ></div>
          );
        else if (
          params.row.plateformData.pfProductCodes.ARTPRM == "C" &&
          params.row.plateformData.pfProductCodes.ARTSAI == "N"
        ) {
          return (
            <div
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: stringToColor("contremarque"),
                display: "inline-block",
              }}
              title="contremarque"
            ></div>
          );
        }
      },
    },

    {
      field: "gencod",
      headerName: "Code EAN",
      width: 150,
      hideable: true,
      sortable: false,
    },

    {
      field: "plateformData.pfProductPrice.ARVHTC",
      headerName: "Prix de cession PF actuel ",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        parseFloat(params.row.plateformData.pfProductPrice.ARVHTC).toFixed(2) ??
        "",
      cellClassName: (params) => {
        const sellingPrice = params.value ? parseFloat(params.value) : 0;
        const cliSellingPrice = params.row.clientData.pfProductBuyingPrice
          .ARPPXA
          ? parseFloat(params.row.clientData.pfProductBuyingPrice.ARPPXA)
          : 0;

        const cliDate = parseDate(
          params.row.clientData.pfProductBuyingPrice.ARPDPX
        );
        const pfDate = parseDate(
          params.row.plateformData.pfProductPrice.ARVDEB
        );
        console.log("clidate", cliDate, pfDate);
        if (cliDate!! < pfDate!!) {
          return "blue-cell";
        }

        return sellingPrice < cliSellingPrice
          ? "green-cell"
          : sellingPrice > cliSellingPrice
          ? "red-cell"
          : "";
      },
      renderCell: (params) => {
        const sellingPrice = params.row.plateformData.pfProductPrice.ARVHTC;
        const comparisonPrice =
          params.row.clientData.pfProductBuyingPrice.ARPPXA;
        const cliDate = parseDate(
          params.row.clientData.pfProductBuyingPrice.ARPDPX
        );
        const pfDate = parseDate(
          params.row.plateformData.pfProductPrice.ARVDEB
        );

        const arrowIcon =
          cliDate!! < pfDate!! ? (
            ""
          ) : sellingPrice && comparisonPrice ? (
            sellingPrice < comparisonPrice ? (
              <NorthEastIcon style={{ color: "green" }} />
            ) : sellingPrice > comparisonPrice ? (
              <SouthEastIcon style={{ color: "red" }} />
            ) : (
              "="
            )
          ) : (
            ""
          );

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {sellingPrice} € {arrowIcon}
          </div>
        );
      },
    }, // Added Selling Price field for PF
    {
      field: "prix cession momment",
      headerName: "Prix de céssion PF lors de l'achat ",
      width: 150,
      sortable: false,
      valueGetter: (params) =>
        parseFloat(
          params.row.plateformData.pfProductPrice.ARVHTC_moment
        ).toFixed(2) + "€",
    },

    {
      field: "rentability",
      headerName: "Rentabilité",
      width: 150,
      sortable: false,
      valueGetter: (params) => getRentability(params),
    },

    {
      field: "BuyingPrice",
      headerName: "Prix d'achat CLI",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        parseFloat(params.row.clientData.pfProductBuyingPrice.ARPPXA).toFixed(
          2
        ) ?? "",
      cellClassName: (params) => {
        const sellingPrice = params.value ? parseFloat(params.value) : 0;
        const pfSellingPrice = params.row.plateformData.pfProductPrice.ARVHTC
          ? parseFloat(params.row.plateformData.pfProductPrice.ARVHTC)
          : 0;

        const cliDate = parseDate(
          params.row.clientData.pfProductBuyingPrice.ARPDPX
        );
        const pfDate = parseDate(
          params.row.plateformData.pfProductPrice.ARVDEB
        );

        if (cliDate!! < pfDate!!) {
          return "blue-cell";
        }

        return sellingPrice < pfSellingPrice
          ? "green-cell"
          : sellingPrice > pfSellingPrice
          ? "red-cell"
          : "";
      },
      renderCell: (params) => {
        const sellingPrice = params.row.clientData.pfProductBuyingPrice.ARPPXA;
        const comparisonPrice = params.row.plateformData.pfProductPrice.ARVHTC;
        const cliDate = parseDate(
          params.row.clientData.pfProductBuyingPrice.ARPDPX
        );
        const pfDate = parseDate(
          params.row.plateformData.pfProductPrice.ARVDEB
        );

        const arrowIcon =
          cliDate!! < pfDate!! ? (
            ""
          ) : sellingPrice && comparisonPrice ? (
            sellingPrice < comparisonPrice ? (
              <NorthEastIcon style={{ color: "green" }} />
            ) : sellingPrice > comparisonPrice ? (
              <SouthEastIcon style={{ color: "red" }} />
            ) : (
              "="
            )
          ) : (
            <SouthEastIcon style={{ color: "red" }} />
          );

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {sellingPrice} € {arrowIcon}
          </div>
        );
      },
    },
    {
      field: "prix d'achat moyen",
      headerName: "Prix d'achat moyen CLI",
      width: 150,
      sortable: false,
      valueGetter: (params) =>
        parseFloat(params.row.statData.BuyingPricesAverage).toFixed(2) + "€",
    },
    // Added Selling Price field for CLI
    {
      field: "priceGapPercentage",
      headerName: "Écart PF vs CLI %",
      width: 150,
      hideable: true,
      sortable: false,
      // Ensures that the DataGrid treats this column as numeric
      valueGetter: (params) => {
        const pfSellingPrice = params.row.plateformData.pfProductPrice.ARVHTC
          ? parseFloat(params.row.plateformData.pfProductPrice.ARVHTC)
          : 0;
        const cliBuyingPrice = params.row.clientData.pfProductBuyingPrice.ARPPXA
          ? parseFloat(params.row.clientData.pfProductBuyingPrice.ARPPXA)
          : 0;

        if (cliBuyingPrice === 0) {
          return null; // Return null if cliBuyingPrice is zero to avoid division by zero
        }

        const percentageGap =
          ((cliBuyingPrice - pfSellingPrice) / pfSellingPrice) * 100;

        return percentageGap; // Return the numeric value directly
      },
      renderCell: (params) => {
        const percentageGap = parseFloat(params.value);
        if (isNaN(percentageGap)) {
          return "";
        }

        return <div>{percentageGap.toFixed(2)} %</div>;
      },
    },

    {
      field: "PF.buyingPriceNet",
      headerName: "Prix d'achat net PF",
      width: 180,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.plateformData.pfProductBuyingPrice.ARPPXA?.toFixed(2) + " €",
    },
    {
      field: "PF.buyingPriceGross",
      headerName: "Prix d'achat brut PF",
      width: 200,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.plateformData.pfProductBuyingPrice.ARPPXB?.toFixed(2) + " €",
    }, // Added Buying Price Gross field for PF
    {
      field: "PF.ApplicationDate",
      headerName: "Date d'application PF ",
      width: 200,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.plateformData.pfProductPrice.ARVDEB ?? "",
    },
    // Added Application Date field for PF // Added Buying Price Net field for PF
    {
      field: "ApplicationDate",
      headerName: "Date d'application CLI ",
      width: 200,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.clientData.pfProductBuyingPrice.ARPDPX ?? "",
    },
    {
      field: "PF.codeArticle",
      headerName: "Code Article PF",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.plateformData.pfProductCodes.ARTCOD ?? "",
    }, // Added Code Article field
    {
      field: "PF.codeSociete",
      headerName: "Code Société PF",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.plateformData.pfProductCodes.ARTSOC ?? "",
    }, // Added Code Societe field

    {
      field: "PF.codeRef",
      headerName: "Référence fournisseur PF",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.plateformData.pfProductCodes.ARAREFF ?? "",
    },
    {
      field: "SupplierName2",
      headerName: "Fournisseur PF",
      width: 200,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.plateformData.pfProductCodes.FRNOMS ?? "",
    },
    {
      field: "PF.libe1",
      headerName: "Libellé 1 PF",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.plateformData.pfProductsNames.ARTLIB ?? "",
    }, // Added Libe1 field for PF
    {
      field: "PF.lib2",
      headerName: "Libellé 2 PF",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.plateformData.pfProductsNames.ARTLI2 ?? "",
    }, // Added Lib2 field for PF
    {
      field: "PF.UA",
      headerName: "Unité d'achat PF",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.plateformData.pfProductsUnities.CVSUA ?? "",
    }, // Added UA field for PF

    {
      field: "PF.UV",
      headerName: "Unité de vente PF ",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.plateformData.pfProductsUnities.CVSUV ?? "",
    }, // Added UV field for PF

    {
      field: "cli.codeArticle",
      headerName: "Code Article CLI",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.clientData.pfProductCodes.ARTCOD ?? "",
    }, // Added Code Article field for CLI
    {
      field: "cli.codeSociete",
      headerName: "Code Société CLI",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.clientData.pfProductCodes.ARTSOC ?? "",
    },
    // Added Code Societe field for CLI
    {
      field: "cli.codeRef",
      headerName: "Référence fournisseur CLI",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.clientData.pfProductCodes.ARAREFF ?? "",
    },
    {
      field: "libe1",
      headerName: "Libellé 1 CLI",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.clientData.pfProductsNames.ARTLIB ?? "",
    }, // Added Libe1 field for CLI
    {
      field: "lib2",
      headerName: "Libellé 2 CLI",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.clientData.pfProductsNames.ARTLI2 ?? "",
    }, // Added Lib2 field for CLI
    {
      field: "UA",
      headerName: "Unité d'achat CLI",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.clientData.pfProductsUnities.CVSUA ?? "",
    },
    {
      field: "buyingPriceGross",
      headerName: "Prix d'achat brut CLI",
      width: 200,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.clientData.pfProductBuyingPrice.ARPPXB?.toFixed(2) + " €",
    },
    {
      field: "prix cession moyen",
      headerName: "Prix de vente moyen CLI  ",
      width: 150,
      sortable: false,
      valueGetter: (params) =>
        parseFloat(params.row.statData.SalesPriceAverage).toFixed(2) + "€",
    },
    {
      field: "SupplierName",
      headerName: "Fournisseur CLI",
      width: 200,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.clientData.pfProductCodes.FRNOMS ?? "",
    },

    {
      field: "PF.stock",
      headerName: "stock adhérent",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.clientData.pfProductCodes.STKQTE +
        " " +
        (params.row.clientData.pfProductsUnities.CVSUS ?? ""),
    },
    {
      field: "statData.AvaibleStock",
      headerName: "Stock disponible",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.statData?.AvaibleStock?.toFixed(2) +
        " " +
        (params.row.clientData.pfProductsUnities.CVSUS ?? ""),
    },
    {
      field: "statData.topConcordance",
      headerName: "Top Concordance",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) => params.row.statData?.topConcordance ?? "",
    },
    {
      field: "statData.consomationAnuelle",
      headerName: "Consommation Annuelle CLI ",
      width: 180,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.statData?.consomationAnuelle?.toFixed(4) +
        " " +
        (params.row.clientData.pfProductsUnities.CVSUV ?? ""),
    },
    {
      field: "statData.consomationAnuellepf",
      headerName: "Consommation Annuelle PF ",
      width: 180,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.statData?.consomationAnuellePf?.toFixed(4) +
        " " +
        (params.row.plateformData.pfProductsUnities.CVSUV ?? ""),
    },
    {
      field: "statData.totalQuantity",
      headerName: "Quantité achetée a la plateforme",
      width: 180,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.statData?.BuyingQuantityPf?.toFixed(4) +
        "/" +
        (params.row.statData?.BuyingQuantityTotal?.toFixed(4) ?? ""),
    },
    {
      field: "statData.QuantityPromotionMini",
      headerName: "Remise Quantitative Mini",
      width: 180,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.statData?.QuantityPromotionMini?.toFixed(4) +
        " " +
        (params.row.clientData.pfProductsUnities.CVSUV ?? ""),
    },
    {
      field: "statData.TauxPromotionMini",
      headerName: "Taux de Remise Mini",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.statData?.TauxPromotionMini?.toFixed(2) + " %",
    },
    {
      field: "statData.QuantityPromotionMaxi",
      headerName: "Remise Quantitative Maxi",
      width: 180,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.statData?.QuantityPromotionMaxi?.toFixed(4) +
        " " +
        (params.row.clientData.pfProductsUnities.CVSUV ?? ""),
    },
    {
      field: "statData.TauxPromotionMaxi",
      headerName: "Taux de Remise Mini",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.statData?.TauxPromotionMaxi?.toFixed(2) + " %",
    },
    // {
    //   field: "statData.BuyingQuantityPf",
    //   headerName: "Quantité Achat PF",
    //   width: 150,
    //   hideable: true,
    //   sortable: false,
    //   valueGetter: (params) => params.row.statData?.BuyingQuantityPf?.toFixed(4) ?? "",
    // },

    {
      field: "repData.taxType",
      headerName: "Type de Taxe",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) => params.row.repData?.taxType ?? "",
    },
    {
      field: "repData.taxRate",
      headerName: "Taux de Taxe",
      width: 150,
      hideable: true,
      sortable: false,
      valueGetter: (params) => params.row.repData?.taxRate?.toFixed(2) ?? "",
    },
  ];

  // Custom tooltip component
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      console.log("payload", payload);
      const percentage = (
        (payload[0].value / parseInt(totalPages)) *
        100
      ).toFixed(2);
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p className="label">{`${payload[0].name} : ${percentage}%`}</p>
        </div>
      );
    }

    return null;
  };

  interface CustomLabelProps extends PieLabelRenderProps {
    name: string;
    percent: number;
  }
  const isDisabled = selectedClientBase === "";
  const buttonStyle = {
    color: isDisabled ? "gray" : "black",
    background: isDisabled ? "#FFEA7F" : "#FFD100", // Lighter shade when disabled
    cursor: isDisabled ? "not-allowed" : "pointer",
  };

  const CustomToolBar: any = () => {
    const handleExport = useCallback(() => {
      const roundTotalPages = Math.ceil(parseInt(totalPages) / 100);
      const pageSize = 100;

      const calculateEcartPFvsCLI = (
        pfSellingPrice: number,
        cliBuyingPrice: number
      ) => {
        if (cliBuyingPrice === 0) return 0;
        return ((cliBuyingPrice - pfSellingPrice) / pfSellingPrice) * 100;
      };
      const calculateRentabilite = (
        sellingPrice: number | string,
        comparisonPrice: number | string,
        cliDate: number | Date | null,
        pfDate: number | Date | null
      ) => {
        if (!cliDate || !pfDate || cliDate < pfDate) {
          return "Prix non actualisé";
        }

        const parsedSellingPrice = parseFloat(sellingPrice as string);
        const parsedComparisonPrice = parseFloat(comparisonPrice as string);

        if (isNaN(parsedSellingPrice) || isNaN(parsedComparisonPrice)) {
          return "Non rentable";
        }

        if (parsedSellingPrice < parsedComparisonPrice) {
          return "Meilleur prix PF";
        } else if (parsedSellingPrice > parsedComparisonPrice) {
          return "Meilleur prix CLI";
        }

        return "Rentable";
      };

      const enrichData = (data: any) => {
        return data.map((item: any) => {
          const pfSellingPrice =
            parseFloat(item.plateformData.pfProductPrice.ARVHTC) || 0;
          const cliBuyingPrice =
            parseFloat(item.clientData.pfProductBuyingPrice.ARPPXA) || 0;

          const cliDate = parseDate(
            item.clientData.pfProductBuyingPrice.ARPDPX
          );
          const pfDate = parseDate(item.plateformData.pfProductPrice.ARVDEB);

          const ecartPFvsCLI = calculateEcartPFvsCLI(
            pfSellingPrice,
            cliBuyingPrice
          );
          const rentabilite = calculateRentabilite(
            pfSellingPrice,
            cliBuyingPrice,
            cliDate,
            pfDate
          );

          return {
            ...item,
            Rentabilite: rentabilite || "",
            EcartPFvsCLI:
              ecartPFvsCLI !== null ? ecartPFvsCLI.toFixed(2) + "%" : "",
          };
        });
      };

      const formatForExcel = (value: any) => {
        return value == null ? "" : value.toString();
      };

      const generateExcel = (data: any) => {
        const headers = [
          "type d'article",
          "Code EAN",
          "Prix de cession PF actuel",
          "Prix de cession PF lors de l'achat",
          "Prix d'achat CLI",
          "Rentabilité",
          "Écart PF vs CLI %",
          "Date d'application PF",
          "Date d'application CLI",
          "Code Article PF",
          "Code Société PF",
          "Référence Fournisseur PF",
          "Fournissuer PF",
          "Libellé 1 PF",
          "Libellé 2 PF",
          "Unité d'achat PF",
          "Prix d'achat brut PF",
          "Prix d'achat net PF",
          "Unité de vente PF",
          "Code Article CLI",
          "Code Société CLI",
          "Référence Fournisseur CLI",
          "Libellé 1 CLI",
          "Libellé 2 CLI",
          "Unité d'achat CLI",
          "Prix d'achat brut CLI",
          "Fournisseur CLI",
          "Stock CLI",
          "Prix de vente moyen CLI",
          "Prix d'achat moyen CLI",
          "Consommation Annuelle CLI",
          "Consommation Annuelle PF",
          "Remise Quantitative Mini",
          "Taux de Remise Mini",
          "Remise Quantitative  Maxi",
          "Taux de Remise Maxi",
          "Quantité achetée à la plateforme",
          "Quantité totale achetée",
          "Stock disponible",
          "Type de Taxe",
          "Taux de Taxe",
        ];

        const wsData = [
          headers,
          ...data.map((item: any) => {
            const platform = item.plateformData;
            const client = item.clientData;
            const stats = item.statData;
            const rep = item.repData;
            var valuetype = "";

            const artcod = item.plateformData.pfProductCodes.ARTCOD;
            if (artcod.startsWith("99D")) {
              valuetype = "direct";
            } else if (artcod.startsWith("9E")) {
              valuetype = "échantillon";
            } else if (/^K\d+$/.test(artcod)) {
              valuetype = "kit";
            } else if (/^K[A-Z]+$/.test(artcod)) {
              valuetype = "composant kit";
            } else {
              if (
                item.plateformData.pfProductCodes.ARTPRM === "" &&
                item.plateformData.pfProductCodes.ARTSAI === "O"
              ) {
                valuetype = "standard";
              } else if (
                item.plateformData.pfProductCodes.ARTPRM === "C" &&
                item.plateformData.pfProductCodes.ARTSAI === "N"
              ) {
                valuetype = "contremarque";
              } else {
                valuetype = "";
              }
            }

            return [
              formatForExcel(valuetype),
              formatForExcel(item.gencod),
              formatForExcel(platform.pfProductPrice.ARVHTC),
              formatForExcel(platform.pfProductPrice.ARVHTC_moment),
              formatForExcel(client.pfProductBuyingPrice.ARPPXA),
              formatForExcel(item.Rentabilite),
              formatForExcel(item.EcartPFvsCLI),
              formatForExcel(platform.pfProductPrice.ARVDEB),
              formatForExcel(client.pfProductBuyingPrice.ARPDPX),
              formatForExcel(platform.pfProductCodes.ARTCOD),
              formatForExcel(platform.pfProductCodes.ARTSOC),
              formatForExcel(platform.pfProductCodes.ARAREFF),
              formatForExcel(platform.pfProductCodes.FRNOMS),
              formatForExcel(platform.pfProductsNames.ARTLIB),
              formatForExcel(platform.pfProductsNames.ARTLI2),
              formatForExcel(platform.pfProductsUnities.CVSUA),
              formatForExcel(platform.pfProductBuyingPrice.ARPPXB),
              formatForExcel(platform.pfProductBuyingPrice.ARPPXA),
              formatForExcel(platform.pfProductsUnities.CVSUV),
              formatForExcel(client.pfProductCodes.ARTCOD),
              formatForExcel(client.pfProductCodes.ARTSOC),
              formatForExcel(client.pfProductCodes.ARAREFF),
              formatForExcel(client.pfProductsNames.ARTLIB),
              formatForExcel(client.pfProductsNames.ARTLI2),
              formatForExcel(client.pfProductsUnities.CVSUA),
              formatForExcel(client.pfProductBuyingPrice.ARPPXB),
              formatForExcel(client.pfProductCodes.FRNOMS),
              formatForExcel(client.pfProductCodes.STKQTE),
              formatForExcel(stats?.SalesPriceAverage),
              formatForExcel(stats?.BuyingPricesAverage),
              formatForExcel(stats?.consomationAnuelle),
              formatForExcel(stats?.consomationAnuellePf),
              formatForExcel(stats?.QuantityPromotionMini),
              formatForExcel(stats?.TauxPromotionMini),
              formatForExcel(stats?.QuantityPromotionMaxi),
              formatForExcel(stats?.TauxPromotionMaxi),
              formatForExcel(stats?.BuyingQuantityPf),
              formatForExcel(stats?.BuyingQuantityTotal),
              formatForExcel(stats?.AvaibleStock),
              formatForExcel(rep?.taxType),
              formatForExcel(rep?.taxRate),
            ];
          }),
        ];

        const ws = XLSX.utils.aoa_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, "comparateur_de_prix.xlsx");
      };

      const exportToExcel = (data: any) => {
        const enrichedData = enrichData(data);
        const filteredData = isBestPriceSelected
          ? enrichedData.filter(
              (item: any) => item.Rentabilite === "Meilleur prix CLI"
            )
          : enrichedData;

        generateExcel(filteredData);
      };

      const fetchData = async () => {
        setLoading(true);
        let allResults: any[] = [];
        const packet = 50;

        try {
          const fetchBatch = async (start: number, end: number) => {
            const promises: any[] = [];
            for (let index = start; index <= end; index++) {
              const promise = axios
                .get(
                  `https://api.prod.gcp.toutfaire.com/v1/gw/v1/apitools/pricecomparator/compareAll?feature=${selectedEndpoint}&database=${selectedClientBase}&pageNumber=${index}&pageSize=${pageSize}&orderBy=${selectedFilterOrderby}&fieldToOrder=${selectedFilter}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                )
                .then((res) => res.data.out_data.articles);
              promises.push(promise);
            }
            const results = await Promise.all(promises);
            return results.flat();
          };

          if (roundTotalPages > 90) {
            const firstBatch = await fetchBatch(1, 50);
            allResults = [...allResults, ...firstBatch];

            const secondBatch = await fetchBatch(51, roundTotalPages);
            allResults = [...allResults, ...secondBatch];
          } else {
            allResults = await fetchBatch(1, roundTotalPages);
          }

          setTotalResultForCsv(allResults);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
          exportToExcel(allResults);
        }
      };

      fetchData();
    }, [totalPages, selectedEndpoint, selectedClientBase, isBestPriceSelected]);
    return (
      <GridToolbarContainer>
        <LoadingButton
          onClick={handleExport}
          loading={loading}
          loadingPosition="start"
          startIcon={<SaveAlt />}
          variant="text"
        >
          <span style={{ marginTop: 5 }}>Export XLSX</span>
        </LoadingButton>

        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Comparaison Prix Plateforme - {displayedClientName}{" "}
          </Typography>
        </Box>
      </GridToolbarContainer>
    );
  };
  return (
    <MiniDrawer width={widthDrawer} setWidth={setWidthDrawer}>
      <Box style={{ flexGrow: 1, padding: "85px 0px 0px 32px" }}>
        <div style={{ flexGrow: 1, alignItems: "center", width: widthDrawer }}>
          {/* Header / breadcrums  */}
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Accueil
            </Link>
            <Typography color="text.primary">Comparateur de prix</Typography>
          </Breadcrumbs>
          {/* title  */}
          <Typography
            variant="h3"
            style={{
              margin: "10px 10px 10px 0",
              fontWeight: "bold",
              fontSize: "32px",
            }}
          >
            Comparateur de prix
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Stack direction="row" style={{ gap: "10px" }}>
              <FormControl style={{ minWidth: 120 }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={roleError || []}
                  getOptionLabel={(option: any) => option.clientName}
                  getOptionKey={(option: any) => option.databaseName}
                  onChange={handleClientBaseChange}
                  sx={{ width: 200, zIndex: 1 }}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Adherent" />
                  )}
                />
              </FormControl>
              <TextField
                label="Code EAN"
                variant="outlined"
                value={gencod}
                onChange={handleGencodChange}
                error={errorGencod}
                helperText={errorMessage}
                size="small"
              />
              <Autocomplete
                disablePortal
                id="filter-autocomplete"
                options={optionsForFilter}
                getOptionLabel={(option) => option.label}
                value={
                  optionsForFilter.find(
                    (opt) => opt.value === selectedFilter
                  ) || null
                }
                onChange={(event, newValue) =>
                  setSelectedFilter(newValue?.value || "")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter"
                    variant="outlined"
                    size="small"
                  />
                )}
                sx={{ width: 150 }}
              />
              <Autocomplete
                disablePortal
                id="filter-autocomplete2"
                options={["ASC", "DESC"]}
                onChange={(event, newValue: any) =>
                  setSelectedFilterOrderby(newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ordre"
                    variant="outlined"
                    size="small"
                  />
                )}
                sx={{ width: 150 }}
              />
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.9 }}
              >
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                  style={buttonStyle}
                  sx={{ maxHeight: "40px" }}
                  disabled={isDisabled}
                >
                  Comparer &nbsp;
                  <GrCompare />
                </Button>
              </motion.div>
            </Stack>
          </div>
          <div style={{ padding: "0 0 0 5px" }}>
            <RadioGroup onChange={handleRadioChange} value={selectedOption} row>
              <FormControlLabel
                value=""
                control={<Radio />}
                label="Tous les prix"
                disabled={!validData || gencod !== ""}
              />

              <>
                <FormControlLabel
                  value="BETTERPF"
                  control={<Radio />}
                  label="Prix plateforme meilleur"
                  disabled={!validData || gencod !== ""}
                />
                <FormControlLabel
                  value="WRONG"
                  control={<Radio />}
                  label="Prix non-actualisés"
                  disabled={!validData || gencod !== ""}
                />
                <FormControlLabel
                  value="BETTERCLI"
                  control={<Radio />}
                  label="Prix adhérent meilleur"
                  disabled={!validData || gencod !== ""}
                  checked={isBestPriceSelected}
                  onChange={() => setIsBestPriceSelected(true)}
                />
              </>
            </RadioGroup>
          </div>
        </div>
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-start",
          padding: "0 0 0 25px",
        }}
      >
        {/* <Input
          value={selectedFilter}
          onChange={(e) => setSelectedFilter(e.target.value)}
        ></Input> */}
        {/* <Input
          value={selectedFilterOrderby}
          onChange={(e) => setSelectedFilterOrderby(e.target.value)}
        ></Input> */}
        <Switch
          checked={showCharts}
          onChange={toggleCharts}
          inputProps={{ "aria-label": "Toggle charts" }}
          disabled={priceComparisonResult.length === 0}

          // disabled={isToggleDisabled}
        />
        <Typography
          variant="body1"
          style={{ marginRight: "10px", display: "flex", alignItems: "center" }}
        >
          Afficher les graphiques
        </Typography>
      </Box>

      {error ? (
        <Typography variant="body1" style={{ color: "red", margin: "20px" }}>
          {error}
        </Typography>
      ) : (
        priceComparisonResult.length > 0 && (
          <div
            style={{
              display: "flex",

              justifyContent: "center",
              flexDirection: "column",
              padding: "0 0 0 35px",
              width: widthDrawer,
            }}
          >
            {showCharts && !loading && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Card style={{ margin: "20px 0px", height: "400px" }}>
                      <CardHeader
                        title="Comparaison par EAN"
                        titleTypographyProps={{
                          variant: "h6",
                          textAlign: "center",
                        }}
                        style={{
                          backgroundColor: "#f5f5f5",
                          margin: "8px",
                          padding: "5px",
                          borderRadius: "5px",
                        }}
                      />
                      <CardContent>
                        <ResponsiveContainer width="100%" height={300}>
                          <BarChart
                            data={priceComparisonResult}
                            margin={{ top: 20, right: 5, left: -20, bottom: 5 }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="gencod" tick={false} />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar
                              dataKey="clientData.pfProductBuyingPrice.ARPPXA"
                              fill="#4e73df"
                              name="Prix Adhérent"
                            />
                            <Bar
                              dataKey="plateformData.pfProductPrice.ARVHTC"
                              fill="#82ca9d"
                              name="Prix Plateforme"
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>
                  </Grid>

                  {priceComparisonResult.length !== 1 &&
                    selectedEndpoint === "" &&
                    !loading && (
                      <Grid item xs={12} md={4}>
                        <Card style={{ margin: "20px 0px", height: "400px" }}>
                          <CardHeader
                            title="Pourcentage des prix plateforme et prix adhérents"
                            titleTypographyProps={{
                              variant: "h6",
                              textAlign: "center",
                            }}
                            style={{
                              backgroundColor: "#f5f5f5",
                              margin: "8px",
                              padding: "5px",
                              borderRadius: "5px",
                            }}
                          />

                          <CardContent>
                            <ResponsiveContainer width="100%" height={290}>
                              <PieChart>
                                <Pie
                                  data={getPieChartData()}
                                  cx={"50%"}
                                  cy={"46%"}
                                  outerRadius={100}
                                  innerRadius={60}
                                  labelLine={false}
                                  fill="#4e73df"
                                  dataKey="value"
                                  style={{ outline: "none" }}
                                >
                                  <Cell
                                    key="match"
                                    fill="#4caf50"
                                    style={{ outline: "none" }}
                                  />
                                  <Cell
                                    key="nonMatch"
                                    fill="#f44336"
                                    style={{ outline: "none" }}
                                  />
                                  <Label
                                    value={`${greenPercentage()}%`}
                                    position="center"
                                    fill="#4caf50"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                    }}
                                  />
                                </Pie>
                                <Tooltip content={<CustomTooltip />} />

                                <Legend
                                  verticalAlign="bottom"
                                  align="center"
                                  layout="vertical"
                                  wrapperStyle={{ paddingLeft: "20px" }}
                                />
                              </PieChart>
                            </ResponsiveContainer>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}

                  {/* New Pie Chart */}
                  {priceComparisonResult.length !== 1 && (
                    <Grid item xs={12} md={4}>
                      <Card style={{ margin: "20px 0px", height: "400px" }}>
                        <CardHeader
                          title="Pourcentage des articles achetés sur plateforme"
                          titleTypographyProps={{
                            variant: "h6",
                            textAlign: "center",
                          }}
                          style={{
                            backgroundColor: "#f5f5f5",
                            margin: "8px",
                            padding: "5px",
                            borderRadius: "5px",
                          }}
                        />
                        <CardContent>
                          <ResponsiveContainer width="100%" height={290}>
                            <PieChart>
                              <Pie
                                data={matchingData}
                                cx={"50%"}
                                cy={"41%"}
                                outerRadius={100}
                                innerRadius={60}
                                labelLine={false}
                                fill="#8884d8"
                                dataKey="value"
                                style={{ outline: "none" }}
                              >
                                <Cell
                                  key="matching"
                                  fill="#FFD100"
                                  style={{ outline: "none" }}
                                />
                                <Cell
                                  key="nonMatching"
                                  fill="#e74a3b"
                                  style={{ outline: "none" }}
                                />
                                <Label
                                  value={`${(
                                    (matchingData[0].value /
                                      (matchingData[0].value +
                                        matchingData[1].value)) *
                                    100
                                  ).toFixed(2)}%`}
                                  position="center"
                                  fill="#FFD100"
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                  }}
                                />
                              </Pie>
                              <Tooltip content={<CustomTooltip />} />
                              <Legend
                                verticalAlign="bottom"
                                align="center"
                                layout="vertical"
                                wrapperStyle={{ paddingLeft: "20px" }}
                              />
                            </PieChart>
                          </ResponsiveContainer>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
            <Grid container marginRight={"25px"} paddingBottom={"20px"}>
              <Grid item xs={12}>
                {/* Wrapper div to hold both the top scrollbar and DataGrid */}
                <div style={{ width: "100%", position: "relative" }}>
                  {/* Top Scrollbar */}
                  <div
                    ref={topScrollbarRef}
                    style={{
                      width: "100%",
                      overflowX: "auto",
                      height: 20,
                      marginBottom: -20, // Moves it closer to DataGrid
                      position: "absolute",
                      top: 0,
                      zIndex: 2,
                      background: "#fff",
                      display: "none",
                    }}
                  >
                    <div style={{ width: "150%" }} />{" "}
                    {/* Adjust width based on column size */}
                  </div>

                  {/* DataGrid Container */}
                  <div
                    style={{
                      height: "80vh",
                      overflow: "auto",
                    }}
                    ref={bottomScrollbarRef}
                  >
                    <DataGrid
                      apiRef={apiRef}
                      sx={{
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                          outline: "none !important",
                        },
                        maxWidth: isSmallScreen ? "89.8vw" : "93.2vw",
                        width: isSmallScreen ? "100%" : "auto",

                        "@media (min-width: 2005px)": {
                          maxWidth: "93.5vw",
                        },
                        "@media (min-width: 2365px)": {
                          maxWidth: "94.5vw",
                        },
                        "@media (min-width: 2495px)": {
                          maxWidth: "94.7vw",
                        },
                        "@media (min-width: 2565px)": {
                          maxWidth: "94.9vw",
                        },
                      }}
                      rows={priceComparisonResult}
                      columns={columns}
                      rowCount={parseInt(totalPages) - 1}
                      initialState={{
                        pagination: {
                          paginationModel: { pageSize: 20, page: 0 },
                        },
                        columns: {
                          columnVisibilityModel: {
                            rentability: false,
                          },
                        },
                      }}
                      paginationMode="server"
                      onPaginationModelChange={handlePaginationModelChange}
                      slots={{
                        toolbar: () => <CustomToolBar />,
                        pagination: PaginationDatagrid,
                      }}
                      localeText={
                        frFR.components.MuiDataGrid.defaultProps.localeText
                      }
                      disableRowSelectionOnClick
                      slotProps={{
                        toolbar: { csvOptions: { allColumns: false } },
                        pagination: {
                          itemsCount: totalRecords,
                          itemsTitle: "éléments",
                          itemsperpage: 20,
                          totalPages: parseInt(totalPagesRecord),
                          currentPage: currentPage + 1,
                          setCurrentPage: setCurrentPage,
                        },
                      }}
                    />
                  </div>
                  <Legend2 />
                </div>
              </Grid>
            </Grid>
          </div>
        )
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </MiniDrawer>
  );
};

export default ComparePrices;

import React, { useState, useEffect, FC, useCallback } from "react";
import {
  DataGrid,
  GridColDef,
  GridCsvExportOptions,
  GridToolbarContainer,
  useGridApiContext,
} from "@mui/x-data-grid";
import "./ExcelDataGrid.css";
import ErrorList from "../ErrorList";
import { PaginationDatagrid } from "../PaginationDatagrid";
import { Box, Typography } from "@mui/material";

interface Props {
  data: { [key: string]: any }[];
  onRowUpdate: (updatedRow: any) => void;
  onInvalidCellsChange: (invalidCells: { [key: string]: string }) => void;
  selectedRow: number | null;
  selectedColumn: string | null;
  apiRef: any;
  index1: any;
  index2: any;
  title: string; // Added title prop
}

//// Purpose of the component Excel Data Grid : Display the data grid and verifiy the validity in the the front first

const ExcelDataGrid: React.FC<Props> = ({
  data,
  onRowUpdate,
  onInvalidCellsChange,
  apiRef,
  index1,
  index2,
  title, // Destructure the title prop
}) => {
  const [invalidCells, setInvalidCells] = useState<{ [key: string]: string }>(
    {}
  );

  useEffect(() => {
    const updatedInvalidCells: any = {};

    data.forEach((row) => {
      validateCell(row, updatedInvalidCells);
    });

    const invalidCellsChanged =
      JSON.stringify(updatedInvalidCells) !== JSON.stringify(invalidCells);

    if (invalidCellsChanged) {
      setInvalidCells(updatedInvalidCells);
      onInvalidCellsChange(updatedInvalidCells);
    }
  }, [data, onInvalidCellsChange]);

  if (!data || data.length === 0) {
    return <div></div>;
  }

  const handleCellEditStart = (params: any) => {
    console.log("event triggered", index1, index2);

    apiRef.current.scrollToIndexes({
      rowIndex: index1,
      colIndex: index2,
    });
  };

  const columns: GridColDef[] = Object.keys(data[0])
    .filter((key) => key !== "id")
    .map((key) => {
      const minWidth = Math.max(key.length * 10, 100); // Adjust multiplier and minimum width as needed
      return {
        field: key,
        headerName: key,
        minWidth: minWidth,
        flex: 0.5,
        editable: true,
      };
    });

  const rowsWithIds = data.map((row, index) => ({
    id: index + 1,
    ...row,
  }));

  const processRowUpdate = (newRow: any) => {
    const updatedRow = { ...newRow };
    const updatedInvalidCells = { ...invalidCells };
    for (const key in updatedRow) {
      if (Object.prototype.hasOwnProperty.call(updatedRow, key)) {
        const value = updatedRow[key];
        if (typeof value === "string" && /^\d+(\.\d+)?$/.test(value)) {
          updatedRow[key] = parseFloat(value);
        }
      }
    }

    if (
      updatedRow.REMQTE !== undefined ||
      updatedRow.Reduction1 !== undefined ||
      updatedRow.Reduction2 !== undefined ||
      updatedRow.Reduction3 !== undefined
    ) {
      validateCell(updatedRow, updatedInvalidCells);
    }

    onRowUpdate(updatedRow);

    return updatedRow;
  };

  const getRowClassName = (params: any) => {
    const rowId = params.id;
    const hasInvalidCells = Object.keys(invalidCells).some((key) =>
      key.startsWith(`${rowId}-`)
    );
    return hasInvalidCells ? "invalid-row" : "";
  };
  const getCellClassName = (params: any) => {
    const cellId = `${params.row.id}-${params.field}`;
    const isError = invalidCells[cellId];
    return isError ? "invalid-cell" : "";
  };

  const getCellTooltip = (params: any) => {
    const cellId = `${params.row.id}-${params.field}`;
    const error = invalidCells[cellId];

    return error ? error : "";
  };
  interface ValidationRule {
    errorMessage: string;
    validate: (value: any, row?: any) => boolean;
    allowEmpty: boolean;
  }
  const validateCell = (
    row: {
      [key: string]: any;
    },
    updatedInvalidCells: {
      [x: string]: string;
    }
  ) => {
    const validateXChar = (
      numberofChar: number,
      allowEmpty: boolean,
      defaultValue: any = null // default value
    ): ValidationRule => ({
      errorMessage: ErrorList.errorLength + " (" + numberofChar + " max)",
      validate: (value: any) => {
        if (
          allowEmpty &&
          (value === null || value === undefined || value === "")
        ) {
          return true; // Allow empty values
        }
        value =
          value === null || value === undefined || value === ""
            ? defaultValue
            : value;
        return (
          (typeof value === "string" && value.length <= numberofChar) ||
          (typeof value === "number" &&
            Number.isInteger(value) &&
            value.toString().length <= numberofChar)
        );
      },
      allowEmpty: allowEmpty,
    });

    const validateExactChar = (
      numberofChar: number,
      allowEmpty: boolean,
      defaultValue: any = null // default value
    ): ValidationRule => ({
      errorMessage:
        ErrorList.errorLength + " (exactement " + numberofChar + ")",
      validate: (value: any) => {
        if (
          allowEmpty &&
          (value === null || value === undefined || value === "")
        ) {
          return true; // Allow empty values
        }
        value =
          value === null || value === undefined || value === ""
            ? defaultValue
            : value;
        return (
          (typeof value === "string" && value.length === numberofChar) ||
          (typeof value === "number" &&
            Number.isInteger(value) &&
            value.toString().length === numberofChar)
        );
      },
      allowEmpty: allowEmpty,
    });

    const validateNumber = (
      allowEmpty: boolean,
      defaultValue: any = null // default value
    ): ValidationRule => ({
      errorMessage: ErrorList.errorDigit,
      validate: (value: any) => {
        if (
          allowEmpty &&
          (value === null || value === undefined || value === "")
        ) {
          return true; // Allow empty values
        }
        value =
          value === null || value === undefined || value === ""
            ? defaultValue
            : value;
        return typeof value === "number" && !isNaN(value);
      },
      allowEmpty: allowEmpty,
    });

    const validateBool = (
      allowEmpty: boolean,
      defaultValue: any = null // default value
    ): ValidationRule => ({
      errorMessage: ErrorList.errorBoolean,
      validate: (value: any) => {
        if (
          allowEmpty &&
          (value === null || value === undefined || value === "")
        ) {
          return true; // Allow empty values
        }
        value =
          value === null || value === undefined || value === ""
            ? defaultValue
            : value;
        return (
          (typeof value === "string" && value === "O") ||
          (typeof value === "string" && value === "N")
        );
      },
      allowEmpty: allowEmpty,
    });
    const validatePercent = (
      allowEmpty: boolean,
      defaultValue: any = null // default value
    ): ValidationRule => ({
      errorMessage: " Valeur possible :V ou P",
      validate: (value: any) => {
        if (
          allowEmpty &&
          (value === null || value === undefined || value === "")
        ) {
          return true; // Allow empty values
        }
        value =
          value === null || value === undefined || value === ""
            ? defaultValue
            : value;
        return (
          (typeof value === "string" && value === "P") ||
          (typeof value === "string" && value === "V")
        );
      },
      allowEmpty: allowEmpty,
    });
    const validationRules: { [key: string]: ValidationRule } = {
      "Code société": validateExactChar(2, false),
      ArticleSoc: validateXChar(2, false), // Using the same validation function for ArticleSoc
      //
      "Code Article": validateXChar(8, false),
      ArticleCode: validateXChar(8, false),
      //
      "Ancien Code": validateXChar(8, true),
      ArticleOld: validateXChar(8, true),
      //
      "Libellé 1": validateXChar(40, false),
      ArticleName: validateXChar(40, false),
      //
      "Libellé 2": validateXChar(40, true),
      ArticleName2: validateXChar(40, true),
      //
      "Grp de famille": validateXChar(2, false),
      Group: validateXChar(2, false),
      //
      Famille: validateXChar(3, false),
      Family: validateXChar(3, false),
      //
      BuyingUnity: validateXChar(2, false),
      UA: validateXChar(2, false),
      //
      StockUnity: validateXChar(2, false),
      US: validateXChar(2, false),
      //
      SalesUnity: validateXChar(2, false),
      UV: validateXChar(2, false),
      //
      "US/UA": validateNumber(false),
      UnityBuyingOnSales: validateNumber(false),
      //
      UnityStockOnSales: validateNumber(false),
      "US/UV": validateNumber(false),
      //
      Provider: validateNumber(false),
      FRN: validateNumber(false),
      //
      "Ref FRN": validateXChar(20, false),
      ReferenceProvider: validateXChar(20, false),
      //
      Gencod: validateXChar(13, false),
      //
      "Gencod 2": validateXChar(13, true),
      Gencod2: validateXChar(13, true),
      //
      "Gencod 3": validateXChar(13, true),
      Gencod3: validateXChar(13, true),
      //

      "prix achat brut": validateNumber(false),
      GrossBuyingPrice: validateNumber(false),
      //

      "Remise 1": validateNumber(true),
      Reduction1: validateNumber(true),
      //
      "Valeur pourcent (V/P)": validatePercent(true),
      PercentOnValueReduction1: validatePercent(true),
      //
      "Remise 2": validateNumber(true),
      Reduction2: validateNumber(true),
      //
      "Valeur pourcent": validatePercent(true),
      PercentOnValueReduction2: validatePercent(true),
      //
      "Remise 3": validateNumber(true),
      Reduction3: validateNumber(true),
      //
      "Valeur pourcent _v": validatePercent(true),
      PercentOnValueReduction3: validatePercent(true),
      //
      "Prix d'achat net": validateNumber(false),
      NetBuyingPrice: validateNumber(false),
      //
      "Prix de base": validateNumber(true),
      BasePrice: validateNumber(true),
      //
      "Prix vente barème A": validateNumber(false),
      SalesPriceWithoutTaxesA: validateNumber(false),
      //
      B: validateNumber(false),
      SalesPriceWithoutTaxesB: validateNumber(false),
      //
      C: validateNumber(false),
      SalesPriceWithoutTaxesC: validateNumber(false),
      //
      D: validateNumber(false),
      SalesPriceWithoutTaxesD: validateNumber(false),
      //
      E: validateNumber(true),
      SalesPriceWithoutTaxesE: validateNumber(true),
      //
      F: validateNumber(true),
      SalesPriceWithoutTaxesF: validateNumber(true),
      //
      G: validateNumber(true),
      SalesPriceWithoutTaxesG: validateNumber(true),
      //
      H: validateNumber(true),
      SalesPriceWithoutTaxesH: validateNumber(true),
      //
      "Coef A": validateNumber(false),
      CoefficientA: validateNumber(false),
      //
      "Coef B": validateNumber(false),
      CoefficientB: validateNumber(false),
      //
      "Coef C": validateNumber(false),
      CoefficientC: validateNumber(false),
      //
      "Coef D": validateNumber(false),
      CoefficientD: validateNumber(false),
      //
      "Coef E": validateNumber(true),
      CoefficientE: validateNumber(true),
      //
      "Coef F": validateNumber(true),
      CoefficientF: validateNumber(true),
      //
      "Coef G": validateNumber(true),
      CoefficientG: validateNumber(true),
      //
      "Coef H": validateNumber(true),
      CoefficientH: validateNumber(true),
      //
      Qui: validateXChar(10, false),
      ModifiedBy: validateXChar(10, false),
      //

      TAXTYP: {
        errorMessage: "TAXTYP doit être une chaîne de caractères valide",
        validate: (value: any) =>
          typeof value === "string" && value.trim() !== "",
        allowEmpty: true,
      },
      TAXMNT: {
        errorMessage: "TAXMNT doit être un nombre positif",
        validate: (value: any) => typeof value === "number" && value >= 0,
        allowEmpty: true,
      },
      TAXCDO: {
        errorMessage: "TAXCDO doit être une chaîne de caractères valide",
        validate: (value: any) =>
          typeof value === "string" && value.trim() !== "",
        allowEmpty: true,
      },

      Quand: {
        errorMessage: ErrorList.ModifiedOn,

        validate: (value: any) => {
          if (typeof value === "string") {
            // Regular expression for DD/MM/YYYY format
            const ddMmYyyyRegex =
              /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|[2-9][0-9])\d\d$/;

            // Regular expression for YYYY-MM-DD format
            const yyyyMmDdRegex =
              /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
            // Regular expression for DD/MM/YY format
            const ddMmYyRegex =
              /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(\d{2})$/;

            // Validate if the date matches either format
            return (
              ddMmYyyyRegex.test(value) ||
              yyyyMmDdRegex.test(value) ||
              ddMmYyRegex.test(value)
            );
          } else {
            return false;
          }
        },
        allowEmpty: false,
      },

      ModifiedOn: {
        errorMessage: ErrorList.ModifiedOn,
        validate: (value: any) => {
          if (typeof value === "string") {
            // Regular expression for DD/MM/YYYY format
            const ddMmYyyyRegex =
              /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|[2-9][0-9])\d\d$/;

            // Regular expression for YYYY-MM-DD format
            const yyyyMmDdRegex =
              /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
            // Regular expression for DD/MM/YY format
            const ddMmYyRegex =
              /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(\d{2})$/;

            // Validate if the date matches either format
            return (
              ddMmYyyyRegex.test(value) ||
              yyyyMmDdRegex.test(value) ||
              ddMmYyRegex.test(value)
            );
          } else {
            return false;
          }
        },
        allowEmpty: false,
      },
      //

      "Arrondie PXA": validateXChar(1, false),
      RoundedBuyingPrice: validateXChar(1, false),
      //
      "Arrondie PXB": validateXChar(1, false),
      RoundedGrossPrice: validateXChar(1, false),
      //
      "Prix de revient": validateNumber(true),
      CostPrice: validateNumber(true),
      //
      RoundedCostPrice: validateXChar(1, false),
      "Arrondie Prix de revient": validateXChar(1, false),
      //

      "cou tp": validateNumber(true),
      TRSOnUnityAmmount: validateNumber(false),
      //
      Lot: validateBool(false),
      "ls cmem": {
        errorMessage: "La valeur doit être 'O' ou 'N'",
        validate: (value: any) => value === "O" || value === "N",
        allowEmpty: false,
      },
      //
      tva: validateNumber(false),
      VATCode: validateNumber(false),
      //
      ecommerce: {
        errorMessage: "La valeur doit être 'O', 'N' ou 'I'",
        validate: (value: any) =>
          value === "O" || value === "N" || value === "I",
        allowEmpty: false,
      },
      //
      "paquet a la vente": validateNumber(false),
      PacketForSale: validateNumber(false),
      //
      "Code Centrale achat": validateXChar(20, false),
      BuyingCentralCode: validateXChar(20, false),
      //

      "LS GXO": validateBool(false),
      LSArticle: validateBool(false),

      Colisée: {
        errorMessage:
          "La valeur doit être 'O', 'C', 'I', 'E', 'G', 'F', 'H', 'K', 'J', 'X' ou ' '",
        validate: (value: any) =>
          ["O", "C", "I", "E", "G", "F", "H", "K", "J", "X", " "].includes(
            value
          ),
        allowEmpty: false,
      },
      //
      DLUO: validateBool(true),
      ExpiryPeriod: validateBool(true),
      //
      Dangereux: validateBool(false),
      HazardousMaterial: validateBool(false),
      //
      "Craint le gele": validateBool(false),
      FreezingProduct: validateBool(false),
      //
      "Longueur ": validateNumber(false),
      PackageLength: validateNumber(false),
      //
      Largeur: validateNumber(false),
      PackageWidth: validateNumber(false),
      //
      Hauteur: validateNumber(false),
      PackageHeight: validateNumber(false),
      //
      "NB COLIS PAR COUCHE": validateNumber(true),
      NbPackagePerLayer: validateNumber(true),
      //
      "COUCHE PAR PAL": validateNumber(true),
      NbLayerPerPalette: validateNumber(true),
      //
      "Hauteur Couche": validateNumber(true),
      LayerHeight: validateNumber(true),
      //
      Bain: validateBool(false),
      Bath: validateBool(false),

      Calibre: validateBool(false),
      Caliber: validateBool(false),
      //
      Approvisioinneur: validateXChar(10, false),
      Supplier: validateXChar(10, false),
      //
      "Vente annuelle": validateNumber(false),
      AnnualRequestFixed: validateNumber(false),
      //
      "Delai FRN": validateNumber(false),
      SupplierDelay: validateNumber(false),
      //
      "méthode delai": validateXChar(1, false),
      PrevisionMethod: validateXChar(1, false),
      //
      RFACoeff: validateNumber(true),
      "COEF RFA": validateNumber(true),
      //
      "Prix plancher": validateNumber(true),
      MinimalPrice: validateNumber(true),
      // "delai avant benne DLUO": {
      //   errorMessage: ErrorList.errorLength + "(10 max) ",
      //   validate: (value: any) =>
      //     typeof value === "string" && value.length <= 10,
      //   allowEmpty: false,
      // },
      "Longeur palette": validateNumber(true),
      PaletteLength: validateNumber(true),
      //
      "largeur palette": validateNumber(true),
      PaletteWidth: validateNumber(true),
      //
      "Hauteur palette": validateNumber(true),
      PaletteHeight: validateNumber(true),
      //
      "Poids palette": validateNumber(true),
      PaletteWeight: validateNumber(true),
      //
      CVSRFUA: {
        errorMessage: "CVSRFUA doit être égale à UA ou à US",
        validate: (value: any, row: any) => {
          return value === row.UA || value === row.US;
        },
        allowEmpty: false,
      },
      CVSRFSV: {
        errorMessage: "CVSRFSV doit être égale à UV ou à US",
        validate: (value: any, row: any) => {
          return value === row.UV || value === row.US;
        },
        allowEmpty: false,
      },
      CVSU1: {
        errorMessage: "CVSU1 ou CVSRFU1 doit être UA, US ou UV",
        validate: (value: any, row: any) => {
          return (
            value === row.UA ||
            value === row.US ||
            value === row.UV ||
            row.CVSRFU1 === row.UA ||
            row.CVSRFU1 === row.US ||
            row.CVSRFU1 === row.UV
          );
        },
        allowEmpty: false,
      },
      CVSRFU1: {
        errorMessage: "CVSU1 ou CVSRFU1 doit être UA, US ou UV",
        validate: (value: any, row: any) => {
          return (
            value === row.UA ||
            value === row.US ||
            value === row.UV ||
            row.CVSU1 === row.UA ||
            row.CVSU1 === row.US ||
            row.CVSU1 === row.UV
          );
        },
        allowEmpty: false,
      },
      CVSU2: {
        errorMessage: "CVSU2 ou CVSRFU2 doit être UA, US ou UV",
        validate: (value: any, row: any) => {
          return (
            value === row.UA ||
            value === row.US ||
            value === row.UV ||
            row.CVSRFU2 === row.UA ||
            row.CVSRFU2 === row.US ||
            row.CVSRFU2 === row.UV
          );
        },
        allowEmpty: false,
      },
      CVSRFU2: {
        errorMessage: "CVSU2 ou CVSRFU2 doit être UA, US ou UV",
        validate: (value: any, row: any) => {
          return (
            value === row.UA ||
            value === row.US ||
            value === row.UV ||
            row.CVSU2 === row.UA ||
            row.CVSU2 === row.US ||
            row.CVSU2 === row.UV
          );
        },
        allowEmpty: false,
      },
      //

      CVSU2UR: validateNumber(false),
      CVSU2ToCVSRFU2: validateNumber(false),
      //
      Conditionnement: validateNumber(false),
      Conditioning: validateNumber(false),
      //
      "PCB GXO": validateNumber(false),
      NumberOfItemsPerPackage: validateNumber(false),
      //
      "Multiple d'achat": validateNumber(false),
      BuyingMultiple: validateNumber(false),
      //
      ARANBP: validateNumber(false),
      ConditioningPalette: validateNumber(false),
      //
      "delai avant benne DLUO": validateNumber(true),
      DelayBeforDLUO: validateNumber(true),
      //

      //
      "Stock CM": validateBool(false),
      StockCm: validateBool(false),

      REMQTE: validateNumber(true),
      REMTX: validateNumber(true),
      REMDEB: {
        errorMessage: ErrorList.ModifiedOn,
        validate: (value: any, row: any) => {
          if (
            row.REMQTE === null ||
            row.REMQTE === undefined ||
            row.REMQTE === ""
          ) {
            return true; // Allow empty if REMQTE is empty
          }
          if (typeof value === "string") {
            // Regular expression for DD/MM/YYYY format
            const ddMmYyyyRegex =
              /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|[2-9][0-9])\d\d$/;

            // Regular expression for YYYY-MM-DD format
            const yyyyMmDdRegex =
              /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
            // Regular expression for DD/MM/YY format
            const ddMmYyRegex =
              /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(\d{2})$/;

            // Validate if the date matches either format
            return (
              ddMmYyyyRegex.test(value) ||
              yyyyMmDdRegex.test(value) ||
              ddMmYyRegex.test(value)
            );
          } else {
            return false;
          }
        },
        allowEmpty: true, // Allow empty if REMQTE is empty
      },
      REMFIN: {
        errorMessage: ErrorList.ModifiedOn,
        validate: (value: any, row: any) => {
          if (
            row.REMQTE === null ||
            row.REMQTE === undefined ||
            row.REMQTE === ""
          ) {
            return true; // Allow empty if REMQTE is empty
          }
          if (typeof value === "string") {
            // Regular expression for DD/MM/YYYY format
            const ddMmYyyyRegex =
              /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|[2-9][0-9])\d\d$/;

            // Regular expression for YYYY-MM-DD format
            const yyyyMmDdRegex =
              /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
            // Regular expression for DD/MM/YY format
            const ddMmYyRegex =
              /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(\d{2})$/;

            // Validate if the date matches either format
            return (
              ddMmYyyyRegex.test(value) ||
              yyyyMmDdRegex.test(value) ||
              ddMmYyRegex.test(value)
            );
          } else {
            return false;
          }
        },
        allowEmpty: true, // Allow empty if REMQTE is empty
      },
      //
    };

    for (const key in row) {
      if (Object.prototype.hasOwnProperty.call(row, key)) {
        const rule = validationRules[key];
        if (rule) {
          const value = row[key];

          if (!value && rule.allowEmpty) {
            // Skip validation if value is empty and allowEmpty is true
            delete updatedInvalidCells[`${row.id}-${key}`];
          } else if (
            (value === null || value === undefined || value === "") &&
            !rule.allowEmpty
          ) {
            updatedInvalidCells[
              `${row.id}-${key}`
            ] = ` Ce champ ne doit pas etre vide `;
          } else if (!rule.validate(value, row)) {
            updatedInvalidCells[`${row.id}-${key}`] = ` ${rule.errorMessage} `;
          } else {
            delete updatedInvalidCells[`${row.id}-${key}`];
          }
        }
      }
    }

    // Additional validation to check if REMDEB is less than REMFIN
    if (
      row.REMQTE &&
      (row.REMDEB === null || row.REMDEB === undefined || row.REMDEB === "")
    ) {
      updatedInvalidCells[`${row.id}-REMDEB`] =
        "REMDEB doit être renseigné si REMQTE est renseigné";
    }

    // Validation for TAXTYP
    if (row.TAXTYP && typeof row.TAXTYP !== "string") {
      updatedInvalidCells[`${row.id}-TAXTYP`] =
        "TAXTYP doit être une chaîne de caractères valide";
    }
    // Validation for TAXCDO
    if (
      row.TAXCDO &&
      (typeof row.TAXCDO !== "string" || row.TAXCDO.length !== 2)
    ) {
      updatedInvalidCells[`${row.id}-TAXCDO`] =
        "TAXCDO doit être une chaîne de 2 caractères";
    }

    // Validation for TAXORG
    if (row.TAXORG && typeof row.TAXORG !== "number") {
      updatedInvalidCells[`${row.id}-TAXORG`] = "TAXORG doit être un nombre";
    }
    // Validation for TAXMNT
    if (row.TAXMNT && (typeof row.TAXMNT !== "number" || row.TAXMNT < 0)) {
      updatedInvalidCells[`${row.id}-TAXMNT`] =
        "TAXMNT doit être un nombre positif";
    }

    if (
      row.REMQTE &&
      (row.REMFIN === null || row.REMFIN === undefined || row.REMFIN === "")
    ) {
      updatedInvalidCells[`${row.id}-REMFIN`] =
        "REMFIN doit être renseigné si REMQTE est renseigné";
    }
    if ((row.REMDEB && !row.REMQTE) || (row.REMFIN && !row.REMQTE)) {
      updatedInvalidCells[`${row.id}-REMDEB`] =
        "REMQTE doit être renseigné si REMDEB ou REMFIN est renseigné";
      updatedInvalidCells[`${row.id}-REMFIN`] =
        "REMQTE doit être renseigné si REMDEB ou REMFIN est renseigné";
    }
    if (row.REMDEB && row.REMFIN) {
      const parseDate = (dateStr: any) => {
        const parts = dateStr.split("/");
        if (parts.length !== 3) return null; // Validate format

        let day = parseInt(parts[0], 10);
        let month = parseInt(parts[1], 10) - 1; // Months are 0-based in JS
        let year = parseInt(parts[2], 10);

        // Handle two-digit years (assume 2000+ for years < 50)
        year += year < 50 ? 2000 : 1900;

        return new Date(year, month, day);
      };

      const remdebDate = parseDate(row.REMDEB);
      const remfinDate = parseDate(row.REMFIN);

      if (remdebDate && remfinDate && remdebDate >= remfinDate) {
        updatedInvalidCells[`${row.id}-REMDEB`] =
          "REMDEB doit être inférieur à REMFIN";
        updatedInvalidCells[`${row.id}-REMFIN`] =
          "REMFIN doit être supérieur à REMDEB";
      }
    }

    // Calculate net buying price
    const grossBuyingPrice = parseFloat(row["prix achat brut"]) || 0;
    const reduction1 = parseFloat(row["Remise 1"]) || 0;
    const reduction2 = parseFloat(row["Remise 2"]) || 0;
    const reduction3 = parseFloat(row["Remise 3"]) || 0;

    let calculatedNetBuyingPrice = grossBuyingPrice;
    calculatedNetBuyingPrice -= calculatedNetBuyingPrice * (reduction1 / 100);
    calculatedNetBuyingPrice -= calculatedNetBuyingPrice * (reduction2 / 100);
    calculatedNetBuyingPrice -= calculatedNetBuyingPrice * (reduction3 / 100);

    if (row["Prix d'achat net"] !== calculatedNetBuyingPrice) {
      updatedInvalidCells[
        `${row.id}-Prix d'achat net`
      ] = `Le prix d'achat net doit être ${calculatedNetBuyingPrice.toFixed(
        2
      )}€`;
    } else {
      delete updatedInvalidCells[`${row.id}-Prix d'achat net`];
    }

    // Ensure prices are decreasing (A >= B >= C >= D) and correspond with coefficients
    const salesPriceA = parseFloat(row["Prix vente barème A"]) || 0;
    const salesPriceB = parseFloat(row["B"]) || 0;
    const salesPriceC = parseFloat(row["C"]) || 0;
    const salesPriceD = parseFloat(row["D"]) || 0;

    const coefA = parseFloat(row["Coef A"]) || 1;
    const coefB = parseFloat(row["Coef B"]) || 1;
    const coefC = parseFloat(row["Coef C"]) || 1;
    const coefD = parseFloat(row["Coef D"]) || 1;

    const expectedPriceB = (salesPriceA * coefB) / coefA;
    const expectedPriceC = (salesPriceA * coefC) / coefA;
    const expectedPriceD = (salesPriceA * coefD) / coefA;

    if (
      !(
        salesPriceA >= salesPriceB &&
        salesPriceB >= salesPriceC &&
        salesPriceC >= salesPriceD
      )
    ) {
      updatedInvalidCells[`${row.id}-Prix vente barème A`] =
        "Les prix doivent être décroissants (A >= B >= C >= D)";
      updatedInvalidCells[`${row.id}-B`] =
        "Les prix doivent être décroissants (A >= B >= C >= D)";
      updatedInvalidCells[`${row.id}-C`] =
        "Les prix doivent être décroissants (A >= B >= C >= D)";
      updatedInvalidCells[`${row.id}-D`] =
        "Les prix doivent être décroissants (A >= B >= C >= D)";
    } else {
      delete updatedInvalidCells[`${row.id}-Prix vente barème A`];
      delete updatedInvalidCells[`${row.id}-B`];
      delete updatedInvalidCells[`${row.id}-C`];
      delete updatedInvalidCells[`${row.id}-D`];
    }

    if (Math.abs(salesPriceB - expectedPriceB) >= 0.01) {
      updatedInvalidCells[
        `${row.id}-B`
      ] = `Le prix B doit être ${expectedPriceB.toFixed(
        2
      )}€ selon le coefficient`;
    } else {
      delete updatedInvalidCells[`${row.id}-B`];
    }

    if (Math.abs(salesPriceC - expectedPriceC) >= 0.01) {
      updatedInvalidCells[
        `${row.id}-C`
      ] = `Le prix C doit être ${expectedPriceC.toFixed(
        2
      )}€ selon le coefficient`;
    } else {
      delete updatedInvalidCells[`${row.id}-C`];
    }

    if (Math.abs(salesPriceD - expectedPriceD) >= 0.01) {
      updatedInvalidCells[
        `${row.id}-D`
      ] = `Le prix D doit être ${expectedPriceD.toFixed(
        2
      )}€ selon le coefficient`;
    } else {
      delete updatedInvalidCells[`${row.id}-D`];
    }

    // Trigger validation for remdeb and remfin if remqte or reductions are changed

    console.log("invalid", updatedInvalidCells);
    setInvalidCells(updatedInvalidCells);
    onInvalidCellsChange(updatedInvalidCells);
  };

  const CustomToolBar: FC = () => {
    const [loading, setLoading] = useState(false);
    const apiRef = useGridApiContext();

    return (
      <GridToolbarContainer>
        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {title}
          </Typography>
        </Box>
      </GridToolbarContainer>
    );
  };
  return (
    <div style={{ width: "100%", overflow: "auto" }} className="test">
      <DataGrid
        rows={rowsWithIds}
        columns={columns.map((column) => ({
          ...column,
          renderCell: (params) => (
            <div title={getCellTooltip(params)}>{params.value}</div>
          ),
        }))}
        processRowUpdate={processRowUpdate}
        getRowClassName={getRowClassName}
        getCellClassName={getCellClassName}
        apiRef={apiRef}
        onCellModesModelChange={handleCellEditStart}
        slots={{
          toolbar: () => <CustomToolBar />,
          pagination: PaginationDatagrid,
        }}
        slotProps={{
          toolbar: {},
          pagination: {
            itemsCount: data.length, // Total number of records
            itemsTitle: "éléments",
            itemsperpage: data.length,
            totalPages: 1,
            currentPage: 1,
            setCurrentPage: () => {}, // Ne}w prop to set current page

            // Number of items per page
          },
        }}
      />
    </div>
  );
};

export default ExcelDataGrid;

import React, { useState } from "react";
import MiniDrawer from "../../tools/MiniDrawer";
import { Box, Breadcrumbs, Link, Typography } from "@mui/material";

const StatsMarketing: React.FC = () => {
  const [widthDrawer, setWidthDrawer] = useState("");
  return (
    <MiniDrawer width={widthDrawer} setWidth={setWidthDrawer}>
      <Box style={{ flexGrow: 1, padding: "85px 0px 0px 32px" }}>
        <div style={{ flexGrow: 1, alignItems: "center", width: widthDrawer }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Accueil
            </Link>
            <Typography color="text.primary">
              {" "}
              Statistiques Marketing{" "}
            </Typography>
          </Breadcrumbs>
          <Typography
            variant="h3"
            style={{
              margin: "10px 10px 10px 0",
              fontWeight: "bold",
              fontSize: "32px",
            }}
          >
            Statistiques Marketing
          </Typography>{" "}
        </div>
      </Box>
    </MiniDrawer>
  );
};

export default StatsMarketing;
